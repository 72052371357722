import dateFormat from "dateformat";
import React from "react";
import {hot} from "react-hot-loader/root";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {layerActions, getTopVisibleLayer} from "../../../redux/modules/layers";
import ClockRange from "cesium/Source/Core/ClockRange";
import JulianDate from "cesium/Source/Core/JulianDate";
import TimelineControls from "./TimelineControls";
import CesiumTimeline from "./CesiumTimeline";
import {formatDate, formatDateTime,formatYear,formatMonth} from "./DateFormats";
import "./timeline.css";

class Timeline extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeout: undefined,
            multiplier: 1,
            prevStartTime:undefined,
            hasZoomed:false
        }
    }

    revert = () => {
        if (this.state.timeout) {
            clearTimeout(this.state.timeout)
            this.setState({timeout: undefined})
        }
    }


    passTimeline = timeline=>{
        this.timeline = timeline;
    }

    componentWillMount() {
        this.resizeListener = () => this.timeline && this.timeline.resize();

        window.addEventListener("resize", this.resizeListener, false);

        const updateCurrentTimeString = clock => {
            if (this.state.prevStartTime===undefined){
                this.setState({
                    prevStartTime:formatYear(JulianDate.toDate(clock.startTime))
                })

            }
            else if (!this.state.hasZoomed  &&  formatYear(JulianDate.toDate(clock.startTime))!== this.state.prevStartTime){
                // 缩放一次
                if (this.timeline !== undefined){
                    this.setState({
                        hasZoomed:true,
                        prevStartTime:formatYear(JulianDate.toDate(clock.startTime))
                    })
                    this.timeline.zoomTo(clock.startTime,clock.stopTime)

                }
            }

            const time = clock.currentTime;
            let currentTime;
            if (this.props.topVisibleLayer.isTimeSeries) {
                currentTime = dateFormat(time, "yyyy");
            } else {
                // czml
                if (this.props.topVisibleLayer.timelineTic==='yyyy'){

                currentTime = formatYear(JulianDate.toDate(time));
                }else{
                    currentTime = formatMonth(JulianDate.toDate(time));
                }
            }

            this.setState({
                currentTimeString: currentTime
            });

            if (this.props.topVisibleLayer.isTimeSeries && this.props.clock.shouldAnimate && this.props.topVisibleLayer.times.contains(JulianDate.toIso8601(currentTime)) && !this.props.topVisibleLayer.absentSeries.has(currentTime)) {

                const multiplier = +this.props.clock.multiplier;
                this.props.clock.multiplier = 0.0;
                if (!this.state.timeout) {
                    this.setState({
                        timeout: setTimeout(() => {
                            this.setState({timeout: undefined})
                            this.props.clock.multiplier = multiplier
                        }, this.props.topVisibleLayer.waitTime)
                    })
                }
            }
        };

        this.removeTickEvent = this.props.clock.onTick.addEventListener(
            updateCurrentTimeString
        );

        updateCurrentTimeString(this.props.clock);

        this.updateForNewTopLayer();
    }

    componentWillUnmount() {
        this.props.clock.shouldAnimate=false;
        this.removeTickEvent();
        window.removeEventListener("resize", this.resizeListener);
        this.setState({
             hasZoomed:false,
            prevStartTime:undefined,
        })
    }



    updateForNewTopLayer = () => {
        const newTopLayer = this.props.topVisibleLayer;
        this.props.clock.clockRange = ClockRange.LOOP_STOP;
        this.setState({
            layerName: newTopLayer && newTopLayer.name
        });
    }


    render() {

        return (
            <div className={"timeline"}>
                <div className={"text-row"}>
                    <div
                        className={"text-cell"}
                        title="时序"
                    >
                        {this.state.currentTimeString}
                    </div>
                </div>
                <div className={"controlsRow"}>
                    <TimelineControls clock={this.props.clock} layer={this.props.topVisibleLayer}
                                      revert={this.revert}/>
                    <CesiumTimeline passTimeline = {this.passTimeline} />
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state, props) => {
    return {
        clock: (state => state.layers.clock)(state),
        topVisibleLayer: getTopVisibleLayer(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(layerActions, dispatch),
    };
};


export default hot(connect(mapStateToProps, mapDispatchToProps)(Timeline))
