import {withTask} from 'react-palm/tasks'
import {createAction, handleActions} from 'redux-actions'
import {fromPromise} from 'react-palm/tasks/core'

import {SPRING_HOST, get, SVAIL_HOST} from '../../components/utils'

// import {riskTreeData, monitorTreeData} from "../../config/layerConfig";

const _adjustLayerIndex = (layerArray, index, up) => {

    if (up === true) {
        // 上移一层，未使用
        if (index === 0) {
            return [...layerArray]
        }

        return [...layerArray.slice(0, index - 1), layerArray[index], layerArray[index - 1], ...layerArray.slice(index + 1)]

    } else if (up === false) {
        // 下移一层，未使用
        if (index === layerArray.length - 1) {
            return [...layerArray]
        }

        return [...layerArray.slice(0, index), layerArray[index + 1], layerArray[index], ...layerArray.slice(index + 2)]
    } else if (up === 'top') {
        // 置顶
        return [layerArray[index], ...layerArray.slice(0, index), ...layerArray.slice(index + 1)]
    }
}

const _removeLayer = (layerArray, index) => {
    if (index === 0) {
        return layerArray.slice(1)
    } else {
        return [...layerArray.slice(0, index), ...layerArray.slice(index + 1)]
    }
}

const _calcTopVisibleLayer = (layerArray) => {
    for (let i = 0; i < layerArray.length; i++) {
        if (layerArray[i].visible) {
            return layerArray[i].layer
        }
    }
    return undefined;
}

const _toggleCheckedLayer = (state, dirtyKey, checked) => {
    if (checked) {

        //目录树勾选图层
        const layer = state.layerMap.get(dirtyKey)

        let legendUrl = `${SPRING_HOST}/legend/${encodeURIComponent(layer.name)}.png`
        if (!layer.customLegend) {
            legendUrl = `${SVAIL_HOST}/geoserver_new/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&width=30&LAYER=${encodeURI('YDYL:' + dirtyKey)}&STYLE=${encodeURI(state.layerStylesMap.get(dirtyKey) ? 'YDYL:' + dirtyKey + "_" + state.layerStylesMap.get(dirtyKey)[0] : '')}&LEGEND_OPTIONS=dpi:${layer.legendDpi};fontSize:6;bgColor:0x141414;fontColor:0xffffff`
        }

        return [{
            layer: layer,
            visible: true,
            key: dirtyKey,
            attachment:state.layerAttachmentMap[layer.name],
            drawChart: layer.drawChart || false,
            drawEntity: layer.drawEntity || false,
            customLegend: layer.customLegend || false,
            type: layer.type,
            legendUrl,
            layerStyles: state.layerStylesMap.get(dirtyKey)
        }, ...state.checkedLayers]
    } else {

        // 目录树取消勾选

        let position = -1
        const len = state.checkedLayers.length
        for (let i = 0; i < len; i++) {
            if (state.checkedLayers[i]["key"] === dirtyKey) {
                position = i;
                break
            }
        }

        if (position === 0) {
            return [...state.checkedLayers.slice(1)]
        } else {
            return [...state.checkedLayers.slice(0, position), ...state.checkedLayers.slice(position + 1)]
        }
    }
}

const initialState = {
    bottomDrawerOpen: false,
    showGL: false,
    chartTitle: '暂无图表',
    // 当前绘制图表的图层在checkedLayer 数组中的index. 为了在图表面板也能切换图表系列，
    // toggle_layer_style设计成了用index.因为活动图层自带checkedLayer 的index ,但想在图表面板用index比较难受
    // ，需要手动维护这个值。
    chartLayer: {},
    glData: [],
    chartData: {
        labels: [],
        datasets: [{
            label: "",
            backgroundColor: '#36a2eb',
            borderColor: '#fff',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,215,0,0.4)',
            hoverBorderColor: 'rgba(255,215,0,1)',
            data: []
        }]
    },

    // 记录图层key 对应的样式 map,例如：{"10公里现状水资源承载力图":["承包工程完成营业额", "承包工程派出人数", "承包工程年末在外人数", "劳务合作在外人数", "劳务合作年末在外人数"]}
    layerStylesMap: new Map(),

    // 图层名称映射图层 provider
    layerMap: new Map(),

    // 图层名_字段名 ：shader
    shaderMap: new Map(),

    expandedNodes: ["风险评估", "项目监测"],

    checkedNodes: [],

    autoExpandParent: true,

    // 图层目录树数据
    layerTreeData: [],

    // 重点目录树数据
    importantTreeData: [],

    monitorTreeData: [],

    // 当前勾选的的图层，也就是在目录树上勾选了的 {name:"",visible:false}
    checkedLayers: [],

    // 当前可见的最上层图
    topVisibleLayer: undefined,

    layerSearchText: '',

    //更新意味着需要将边界图层置顶
    boundaryLayerKey: 0,

    // cesium时钟，控制时序数据
    clock: undefined,

    lineChartDrawerOpen: false,

    lineChartData: {

        color: "#53B7F8",
        tooltip: {
            trigger: 'axis',
            formatter: (params, ticket, callback) => params[0].name + "年: " + params[0].data.toFixed(2)
        },

        backgroundColor: "transparent",

        toolbox: {
            show: true,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                dataView: {readOnly: false},
                magicType: {type: ['line', 'bar']},
                restore: {},
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: []
        },
        yAxis: {
            type: 'value',
            axisLabel: {

                formatter: function (value, index) {

                    return value.toFixed(2);

                }

            }
        },
        series: []
    },
    layerAttachmentMap:{}

}

const fetchLineChartDataTask = fromPromise(({
                                                layerKey,
                                                lng,
                                                lat
                                            }) => get(`${SPRING_HOST}/chart/getSeriesData.do?layer=${encodeURI(layerKey)}&lng=${lng}&lat=${lat}`))
const fetchChartDataTask = fromPromise(({
                                            layerKey,
                                            layerField
                                        }) => get(`${SPRING_HOST}/chart/getData.do?layer=${encodeURI(layerKey)}&field=${encodeURI(layerField)}`))
const fetchGLDataTask = fromPromise((layerKey) => get(`${SPRING_HOST}/chart/getGL.do?layer=${encodeURI(layerKey)}`))

const toggleBottomDrawer = createAction("toggle_bottom_drawer");
const toggleLineChartDrawer = createAction("toggle_lineChart_drawer");

const toggleLayerVisibility = createAction("layers/toggle_layer_visibility");
const toggleLayerStyle = createAction("layers/toggle_layer_style", (dirtyIndex, layerField) => ({
    dirtyIndex,
    layerField
}));
const expandNodes = createAction("layers/expand_nodes");
const toggleCheckedNodes = createAction("layers/toggle_checked_nodes", (checkedNodes, key, checked) => ({
    checkedNodes, key, checked
}));
const toggleAutoExpandParent = createAction("layers/toggle_auto_expand_parent");
const updateLayerSearchText = createAction('layers/update_layer_search_text');
const moveUpLayer = createAction('layers/move_up_layer');
const moveDownLayer = createAction('layers/move_down_layer');
const raiseAsTopLayer = createAction('layers/raise_as_top_layer');
const removeLayer = createAction('layers/remove_layer');
const setLayerTreeData = createAction('layers/set_layer_tree_data');
const setImportantTreeData = createAction('layers/set_important_tree_data');
const setLayerMap = createAction('layers/set_layer_styles_map');
const setLayerStylesMap = createAction('layers/set_layer_map');
const setChartData = createAction('layers/set_chart_data');
const setGLData = createAction('layers/set_gl_data');
const setBoundaryLayerKey = createAction('layers/set_boundary_layer_key');
const fetchChartData = createAction('layers/fetch_chart_data', (layerKey, layerField) => ({layerKey, layerField}))
const fetchGLData = createAction('layers/fetch_gl_data')
const setChartTitle = createAction('set_chart_title');
const toggleShowGL = createAction('toggle_show_gl');
const setShaderMap = createAction('set_shader_map');
const setClock = createAction('set_clock');
const fetchLineChartData = createAction('fetch_lineChart_data');
const setLineChartData = createAction('set_lineChart_data');
const updateFields = createAction('updateFields');

const reducer = handleActions({
    [updateFields]: (state, {payload}) => {
        return {...state, ...payload}
    },
    [toggleBottomDrawer]: (state, {payload}) => {
        return {...state, bottomDrawerOpen: payload}
    },

    [toggleLineChartDrawer]: (state, {payload}) => {
        return {...state, lineChartDrawerOpen: payload}
    },

    [toggleLayerVisibility]: (state, {payload}) => {
        let checkedLayers = ((layerArray, dirtyIndex) => {
            let copy = [...layerArray];
            let dirtyItem = copy[dirtyIndex]
            dirtyItem.visible = !dirtyItem.visible
            return copy
        })(state.checkedLayers, payload);

        // 如果关闭的图层恰好是当前图表图层，隐藏图表；
        if (state.chartLayer.key === state.checkedLayers[payload].key && !state.checkedLayers[payload].visible) {
            return {
                ...state,
                showGL: false,
                bottomDrawerOpen: false,
                checkedLayers, topVisibleLayer: _calcTopVisibleLayer(checkedLayers)
            }
        }

        return {
            ...state, checkedLayers, topVisibleLayer: _calcTopVisibleLayer(checkedLayers)
        }
    },

    // 切换某图层的数据系列
    [toggleLayerStyle]: (state, {payload}) => {

        let checkedLayers = ((layerArray, dirtyIndex, layerField) => {
            let copy = [...layerArray];
            let dirtyItem = copy[dirtyIndex]
            let layer = state.layerMap.get(dirtyItem.key + '_' + layerField);
            dirtyItem.layer = layer
            dirtyItem.layerStyles.splice(dirtyItem.layerStyles.indexOf(layerField), 1)

            dirtyItem.layerStyles = [layerField, ...dirtyItem.layerStyles];

            // 图表图层的图例 都是 geoserver 默认图例

            dirtyItem.legendUrl = `${SVAIL_HOST}/geoserver_new/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&width=30&LAYER=${encodeURI('YDYL:' + dirtyItem.key)}&STYLE=${encodeURI('YDYL:' + dirtyItem.key + "_" + layerField)}&LEGEND_OPTIONS=dpi:${layer.legendDpi};fontSize:6;bgColor:0x141414;fontColor:0xffffff`


            payload.layerKey = dirtyItem.key

            return copy

        })(state.checkedLayers, payload.dirtyIndex, payload.layerField);


        return {
            ...withTask(state, fetchChartDataTask(payload).map(setChartData)),
            chartTitle: payload.layerKey + "_" + payload.layerField,
            checkedLayers,

            chartLayer: checkedLayers[payload.dirtyIndex],
            chartData: {...state.chartData, datasets: [{...state.chartData.datasets[0], label: payload.layerField}]}
        }
    },

    [expandNodes]: (state, {payload}) => {
        return {...state, expandedNodes: payload}
    },
    [toggleCheckedNodes]: (state, {payload}) => {
        if (payload.key === 'ged201' || payload.key === 'ged201_fullTime') {
            if (payload.checked) {
                return {
                    ...state,
                    checkedNodes: [payload.key],
                    checkedLayers: [],
                }
            }
        }

        let checkedLayers = _toggleCheckedLayer(state, payload.key, payload.checked)

        // 如果【取消勾选】的图层恰好是当前图表图层，删除图表数据；
        if (!payload.checked && state.chartLayer.key === payload.key) {
            return {
                ...state,
                checkedNodes: payload.checkedNodes,
                checkedLayers,
                topVisibleLayer: _calcTopVisibleLayer(checkedLayers),
                chartLayer: {},
                showGL: false,
                bottomDrawerOpen: false,
                chartData: {...initialState.chartData},
                glData: [],
                chartTitle: '暂无图表'
            }
        }
        return {
            ...state,
            checkedNodes: payload.checkedNodes,
            checkedLayers,
            topVisibleLayer: _calcTopVisibleLayer(checkedLayers)
        }
    },
    [toggleAutoExpandParent]: (state, {payload}) => {
        return {...state, autoExpandParent: payload}
    },
    [updateLayerSearchText]: (state, {payload}) => {
        return {...state, layerSearchText: payload}
    },
    [moveUpLayer]: (state, {payload}) => {
        let checkedLayers = _adjustLayerIndex(state.checkedLayers, payload, true)
        return {
            ...state, checkedLayers, topVisibleLayer: _calcTopVisibleLayer(checkedLayers)
        }
    },
    [moveDownLayer]: (state, {payload}) => {
        let checkedLayers = _adjustLayerIndex(state.checkedLayers, payload, false)
        return {
            ...state, checkedLayers, topVisibleLayer: _calcTopVisibleLayer(checkedLayers)
        }
    },
    [raiseAsTopLayer]: (state, {payload}) => {
        let checkedLayers = _adjustLayerIndex(state.checkedLayers, payload, 'top')
        return {
            ...state, checkedLayers, topVisibleLayer: _calcTopVisibleLayer(checkedLayers)
        }
    },
    [removeLayer]: (state, {payload}) => {
        let checkedLayers = _removeLayer(state.checkedLayers, payload)
        // 如果删除图层恰好是当前图表图层，删除图表数据；
        if (state.chartLayer.key === state.checkedLayers[payload].key) {
            return {
                ...state,
                checkedLayers,
                chartLayer: {},
                showGL: false,
                bottomDrawerOpen: false,
                chartData: {...initialState.chartData},
                glData: [],
                chartTitle: '暂无图表',
                checkedNodes: checkedLayers.map(lr => lr.key),
                topVisibleLayer: _calcTopVisibleLayer(checkedLayers)
            }
        }
        return {
            ...state,
            checkedLayers,
            checkedNodes: checkedLayers.map(lr => lr.key),
            topVisibleLayer: _calcTopVisibleLayer(checkedLayers)
        }
    },
    [setLayerTreeData]: (state, {payload}) => {
        return {...state, layerTreeData: payload}
    },
    [setLayerMap]: (state, {payload}) => {

        return {...state, layerMap: payload}
    },

    [setShaderMap]: (state, {payload}) => ({...state, shaderMap: payload}),
    [setLayerStylesMap]: (state, {payload}) => {
        return {...state, layerStylesMap: payload}
    },
    [setChartData]: (state, {payload}) => {
        const {error} = payload;
        if (error) return {...state}
        return {
            ...state,
            chartData: {
                ...state.chartData,
                labels: [...Object.keys(payload)],
                datasets: [{...state.chartData.datasets[0], data: [...Object.values(payload)]}]
            }
        }
    },

    [setLineChartData]: (state, {payload}) => {


        if (!Object.keys(payload).includes("error")) {

            return {
                ...state,
                lineChartDrawerOpen: true,
                lineChartData: {
                    ...state.lineChartData,
                    xAxis: {...state.lineChartData.xAxis, data: [...Object.keys(payload)]},
                    series: [{
                        type: "bar", animationDelay: function (idx) {
                            return idx * 30
                        }, name: "", data: [...Object.values(payload).map(v => +v)]
                    }]
                }
            }
        }

        return {...state, lineChartDrawerOpen: false}
    },

    [setGLData]: (state, {payload}) => {
        return {...state, glData: payload}
    },
    [setBoundaryLayerKey]: (state, {payload}) => {
        return {...state, boundaryLayerKey: payload}
    },
    [fetchChartData]: (state, {payload}) => {

        return withTask(state, fetchChartDataTask(payload).map(setChartData))
    },

    [fetchLineChartData]: (state, {payload}) => {
        return withTask(state, fetchLineChartDataTask(payload).map(setLineChartData))
    },
    [fetchGLData]: (state, {payload}) => {
        if (typeof payload === "number") {
            return withTask(state, fetchGLDataTask(state.checkedLayers[payload].key).map(setGLData))
        }
        return withTask(state, fetchGLDataTask(payload).map(setGLData))
    },
    [setChartTitle]: (state, {payload}) => ({...state, chartTitle: payload}),
    [toggleShowGL]: (state, {payload}) => ({...state, showGL: payload}),
    [setClock]: (state, {payload}) => ({...state, clock: payload})
}, initialState)


export default reducer;
export const layerActions = {
    toggleLayerVisibility,
    toggleLayerStyle,
    expandNodes,
    toggleCheckedNodes,
    toggleAutoExpandParent,
    updateLayerSearchText,
    moveUpLayer,
    moveDownLayer,
    raiseAsTopLayer,
    removeLayer,
    setLayerTreeData,
    setLayerMap,
    setLayerStylesMap,
    setChartData,
    setGLData,
    setBoundaryLayerKey,
    fetchChartData,
    fetchGLData,
    setChartTitle,
    toggleShowGL,
    setShaderMap,
    toggleBottomDrawer,
    setClock,
    toggleLineChartDrawer,
    fetchLineChartData,
    updateFields
}
export const getBoundaryLayerKey = state => state.layers.boundaryLayerKey;
export const getLayerStylesMap = state => state.layers.layerStylesMap;
export const getChartData = state => state.layers.chartData;
export const getLayerMap = state => state.layers.layerMap;
export const getTopVisibleLayer = state => state.layers.topVisibleLayer;
export const getExpandedNodes = state => state.layers.expandedNodes;
export const getCheckedNodes = state => state.layers.checkedNodes;
export const getAutoExpandParent = state => state.layers.autoExpandParent;
export const getLayerTreeData = state => state.layers.layerTreeData;
export const getCheckedLayers = state => state.layers.checkedLayers;
export const getLayerSearchText = state => state.layers.layerSearchText;
export const getBottomDrawerOpen = state => state.layers.bottomDrawerOpen
export const getImportantTreeData = state => state.layers.importantTreeData;
export const getMonitorTreeData = state => state.layers.monitorTreeData;
