/*
 * Created at 2021/3/22 下午8:38 by lhq
 */


import {withTask} from 'react-palm/tasks'
import {createAction, handleActions} from 'redux-actions'
import {fromPromise} from 'react-palm/tasks/core'
import {SPRING_HOST, get,post} from '../../components/utils'


const authInfo = (() => {
    let authInfo = localStorage.getItem("ydyl.loginStorage");
    if (!authInfo) {
        return {
            username: "anonymous",
            remember: false,
            authed: false,
            password: "",
        };
    }
    return JSON.parse(authInfo);
})()



const initState = {

    username: authInfo.username,
    remember: authInfo.remember,
    authed: authInfo.authed,
    shouldRedirect: authInfo.authed,
}


const doRegister = createAction("do_register")


const getAuthInfoTask = fromPromise(() => Promise.resove(localStorage.getItem("ydyl.loginStorage")), "getAuthInfoTask")

const reducer = handleActions({
    [doRegister]: (state, {payload}) => {
        console.log(payload)
        const {username, password, remember} = payload;

        if (username === "slhj" && password === "slhj1234") {
            if (remember) {
                localStorage.setItem("ydyl.loginStorage", JSON.stringify({...payload, authed: true}))
            } else {
                localStorage.removeItem("ydyl.loginStorage")
            }

            return {...state, shouldRedirect: true, authed: true, username: payload.username}
        }

        return {...state, shouldRedirect: false, authed: false}

    },

    // [getAuthState]: (state, {payload}) => {
    //     return withTask(state, getAuthInfoTask().map(toggleAuthed))
    // }

}, initState)

export default reducer

export const registerActions = {

    doRegister
}
