import React, {Component} from "react";
import { Select, Row, Col,Card,Checkbox} from 'antd';
import {layerActions} from "../../redux/modules/layers";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


const buildOptions = (styles) => styles.map(value => ({value}))


class ActiveLayerListItem extends Component {


    onSelectLayerStyle = (style) => {
        this.props.toggleLayerStyle(this.props.index, style)
    }

    render() {
        const card = (<Card
            bordered={false}
            bodyStyle={{display:'none'}}
            style={{width: 300}}
            cover={
                <img src={this.props.legendUrl} alt={"获取图例失败"} style={{width: 'auto'}}/>
            }
        >
        </Card>)
        if (this.props.layerStyles) {
            const styleSelector = <Select value={this.props.layerStyles[0]} options={buildOptions(this.props.layerStyles)}
                                          onChange={this.onSelectLayerStyle} size={"small"}/>
            return (
                <>
                    <Row justify={"start"}>
                        <Col span={12}>
                            {styleSelector}
                        </Col>

                        <Col offset={4} span={8}>
                            <Checkbox checked={this.props.showGL} disabled={!this.props.chartLayer.layer || !this.props.chartLayer.layer.drawEntity}  onChange={e =>{
                                this.props.toggleShowGL(e.target.checked)
                                if (e.target.checked){
                                    this.props.fetchGLData(this.props.chartLayer.key)
                                }
                            } } >
                                三维柱状图
                            </Checkbox>
                        </Col>

                    </Row>
                    <Row justify={"start"}>
                        <Col>
                            {card}
                        </Col>
                    </Row>
                </>)

        } else {
            return card
        }
    }

}

const mapStateToProps = (state, props) => {
    return {
        showGL: (state => state.layers.showGL)(state),
        chartLayer: (state => state.layers.chartLayer)(state),
        legendUrl:(state=>state.layers.checkedLayers[props.index].legendUrl)(state),
        layerStyles:(state=>state.layers.checkedLayers[props.index].layerStyles)(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(layerActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveLayerListItem)
