import React, {Component} from "react";
import {Row, Col, Collapse, Switch, Empty} from 'antd';
import LayerCatelog from "./LayerCatelog";
import ActiveLayerListItem from "../widget/ActiveLayerListItem";
import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {
    layerActions,
    getLayerMap,
    getExpandedNodes,
    getCheckedNodes,
    getAutoExpandParent,
    getLayerTreeData,
    getCheckedLayers,
    getLayerSearchText
} from '../../redux/modules/layers'

import {
    appActions,
} from '../../redux/modules/app'

import SplitPane, {Pane} from "react-split-pane";

import './BasicDataTab.css'
import {ChartIcon, DeleteIcon, MoveUpIcon} from "../utils";

import {tab2onlyLayerSet} from "../../config/layerConfig";


const {Panel} = Collapse;

/**
 * 基础信息
 */
class BasicDataTab extends Component {


    componentDidMount() {

    }


    buildPanel = (data) => {
        return data.map((item, index) => <Panel className={"site-collapse-custom-panel"} header={item.layer.name}
                                                key={item.layer.name}
                                                extra={this.genExtra(item, index)}>
            <Row>
                <Col span={24}>
                    <ActiveLayerListItem key={item.layer.name} index={index} checkedLayer={item}/>
                </Col>
            </Row>

        </Panel>)
    }

    genExtra = (item, index) => {
        const that = this
        const commonAction = [
            <DeleteIcon
                title={"移除图层"}
                className={"collapse-icon"}
                onClick={event => {
                    event.stopPropagation();
                    that.props.removeLayer(index)

                }}
            />,

            // <MoveDownIcon
            //     className={"collapse-icon"}
            //     onClick={event => {
            //         // If you don't want click extra trigger collapse, you can prevent this:
            //         event.stopPropagation();
            //         that.props.moveDownLayer(key)
            //     }}
            // />,

            <MoveUpIcon
                title={"置顶图层"}
                className={"collapse-icon"}
                onClick={event => {
                    event.stopPropagation();
                    that.props.raiseAsTopLayer(index)
                }}
            />,
            <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked checked={item.visible} size={"small"}
                    onChange={(checked, event) => {
                        event.stopPropagation();
                        that.props.toggleLayerVisibility(index)
                    }} style={{marginBottom: "7px"}}
                    title={'显示/隐藏：' + item.key}
            />]
        const drawChart = (<ChartIcon
            className={"collapse-icon"}
            onClick={event => {
                event.stopPropagation();

                that.props.toggleBottomDrawer(true)
                that.props.toggleLayerStyle(index, item.layerStyles[0])
                if (this.props.checkedLayers[index].drawEntity && this.props.showGL) {

                    this.props.fetchGLData(item.key)
                } else if (this.props.showGL) {
                    this.props.toggleShowGL(false)
                }

            }}
        />)
        return item.drawChart ? [drawChart, ...commonAction] : commonAction
    };

    render() {
        return (
            <Row gutter={[0, 24]} justify={"center"}>
                <Col span={24}>
                    <LayerCatelog key={"0"}/>
                </Col>
            </Row>

        )
    }
}


const mapStateToProps = (state, props) => {
    return {

        showGL: (state => state.layers.showGL)(state),
        layerLookup: getLayerMap(state),
        expandedNodes: getExpandedNodes(state),
        checkedNodes: getCheckedNodes(state),
        autoExpandParent: getAutoExpandParent(state),
        layerSearchText: getLayerSearchText(state),
        layerTreeData: getLayerTreeData(state),
        checkedLayers: getCheckedLayers(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(layerActions, dispatch),
        ...bindActionCreators(appActions, dispatch)
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(BasicDataTab));

