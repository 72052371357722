import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './App.css';
import {
    Button,
    Col,
    Collapse,
    Drawer,
    Empty,
    Form,
    Input,
    List,
    Modal,
    notification,
    Row,
    Switch,
    Tabs,
    Tooltip
} from 'antd';
import SplitPane, {Pane} from 'react-split-pane'
// import {autoZoomToLayers} from "../config/layerConfig";
import {CzmlDataSource, Globe, ImageryLayer, Viewer} from "resium";


import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {DashboardOutlined, FundOutlined, LogoutOutlined, UnorderedListOutlined} from '@ant-design/icons';

// import layerTreeData from "../config/layerConfig";
import {
    asyncGet,
    buildLayerMap,
    buildLayerStylesMap,
    buildShaderMap,
    ChartIcon,
    DeleteIcon,
    DocIcon,
    DoubleLeftIcon,
    DoubleRightIcon,
    DownloadIcon,
    MoveUpIcon,
    post,
    SPRING_HOST,
    WMS_URL
} from "./utils";
import ChartDrawer from "./reactviews/ChartDrawer";
import * as Cesium from 'cesium';
import {Credit} from 'cesium';

import baseLayers from "../models/baseMapProviders";

import {
    getAutoExpandParent,
    getBottomDrawerOpen,
    getBoundaryLayerKey,
    getChartData,
    getCheckedLayers,
    getCheckedNodes,
    getExpandedNodes,
    getLayerMap,
    getLayerSearchText,
    getLayerTreeData,
    getTopVisibleLayer,
    layerActions
} from '../redux/modules/layers'

import {
    appActions,
    getHasCountryInfo,
    getMapProvider,
    getPopupCartesian3,
    getPopupCountry,
    getPopupData,
    getPopupOpen,
    getPopupType,
    getPopupXY,
    getSiderOpen,
    getSiderWidth
} from '../redux/modules/app'

import CustomWMSImageryProvider from "./core/CustomWMSImageryProvider";
import BasicDataTab from "./reactviews/BasicDataTab";
import "cesium/Build/Cesium/Widgets/widgets.css";
import CesiumNavigation from "cesium-navigation-es6";
// import CesiumPopup from "./widget/CesiumPopup";
import ReWebGLGlobeDataSource from './core/ReWebGLGlobeDataSource'
import terrainLayers from "../models/terrainProviders";
import {Redirect} from 'react-router-dom'
import {loginActions} from "../redux/modules/login";
import CustomGeocoderService from '../models/CustomGeocoderService'
import RiskEvaluateTab from "./reactviews/RiskEvaluateTab";
import {Radar} from "react-chartjs-2";
import ClockStep from 'cesium/Source/Core/ClockStep'

import Timeline from "./reactviews/TimeLine/Timeline";

import {LineChartDrawer} from "./reactviews/LineChartDrawer";
import ProjectMonitorTab from "./reactviews/ProjectMonitorTab";
import GedViewer from "./reactviews/GedViewer";
import GedFullTimeViewer from "./reactviews/GedFullTimeViewer";
import ResponsivePopup from "./widget/ResponsivePopup";
import ActiveLayerListItem from "./widget/ActiveLayerListItem";
import SceneMode from "cesium/Source/Scene/SceneMode";


const {TabPane} = Tabs;
const {Panel} = Collapse;

const boundryLayer = new CustomWMSImageryProvider({
    url: WMS_URL,
    layers: "一带一路区域边界图148"
})

const options = {

    title: {
        display: true,
        position: 'top',
        fontColor: "#FF8C00",
        padding: 2,
        fontSize: 18,
    },
    legend: {
        display: false,
        position: 'bottom',
        color: "#fff",
        fontColor: '#fff'
    },

    scale: {
        angleLines: {
            display: true,
            color: "#fff",
            lineWidth: 0.6
        },
        gridLines: {
            color: '#bbb',
            lineWidth: 0.5
        },
        pointLabels: {
            fontColor: '#fff',
            fontSize: 12,
            fontStyle: 'bold'
        },
        ticks: {
            showLabelBackdrop: false,
            fontColor: '#fff',
            suggestedMin: 0,
            suggestedMax: 5.0,
            max: 5.0,
            min: 0,
            stepSize: 1,
            display: true
        }
    }
};

Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(0.0, 20, 120.0, 40.0);

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            popupPosition: "Top",
            offset: 150,
        }
    }

    zoomTo = (target) => {
        this.viewer.zoomTo(target)
    }

    // context 向子组件传递便利方法
    getChildContext() {
        return {zoomTo: this.zoomTo};
    }

    componentWillMount() {
        this.props.setRedirect("/")
        this.props.getAuthState();
    }


    componentDidMount() {
        this.props.checkNeedChangePassword();
        if (this.viewer) {

            const viewer = this.viewer

            viewer.camera.flyTo({
                destination: Cesium.Rectangle.fromDegrees(0.0, 20, 120.0, 40.0)
            });

            // 传递时钟，timeline组件使用
            this.viewer.clock.clockStep = ClockStep.TICK_DEPENDENT

            this.props.setClock(this.viewer.clock);

            asyncGet(`${SPRING_HOST}/confs/layerTreeData.js`, false).then(layerTreeData => {
                layerTreeData = eval(layerTreeData)
                this.props.setLayerTreeData(layerTreeData)
                const layerStylesMap = buildLayerStylesMap(layerTreeData)
                this.props.setLayerStylesMap(layerStylesMap)
                const shaderMap = buildShaderMap(layerTreeData)
                this.props.setShaderMap(shaderMap)
                asyncGet(`${SPRING_HOST}/confs/layerBoundaryMap.json`, true).then(layerBoundaryMap => {
                    // layerBoundaryMap = eval(layerBoundaryMap)
                    const layerMap = buildLayerMap(layerTreeData, layerBoundaryMap, viewer.clock)
                    let keys = [...layerMap.keys()]
                    let dict = {}
                    keys.forEach(key => {
                        let provider = layerMap.get(key)
                        if (provider.type === "wms") {
                            dict[key] = provider.name
                        }
                    })
                    // console.log(JSON.stringify(dict))
                    this.props.setLayerMap(layerMap)
                })

                asyncGet(`${SPRING_HOST}/confs/monitorTreeData.json`, true).then(monitorTreeData => {

                    this.props.updateFields({monitorTreeData})
                })

                asyncGet(`${SPRING_HOST}/confs/riskTreeData.json`, true).then(importantTreeData => {

                    this.props.updateFields({importantTreeData})
                })

                asyncGet(`${SPRING_HOST}/layerData/listLayerAttachment`, true).then(layerAttachmentMap => {
                    this.props.updateFields({layerAttachmentMap})
                })

            })


            var options = {};
            // 用于在使用重置导航重置地图视图时设置默认视图控制。接受的值是Cesium.Cartographic 和 Cesium.Rectangle.
            options.defaultResetView = Cesium.Rectangle.fromDegrees(0.0, 20, 120.0, 60.0)
            // 用于启用或禁用罗盘。
            options.enableCompass = true;
            // 用于启用或禁用缩放控件。
            options.enableZoomControls = true;
            // 用于启用或禁用距离图例。
            options.enableDistanceLegend = true;
            // 用于启用或禁用指南针外环。
            options.enableCompassOuterRing = false;

            CesiumNavigation(viewer, options);

            // if (this.scenePostRenderListenerRemover) {
            //     this.scenePostRenderListenerRemover();
            // }
            // this.scenePostRenderListenerRemover = null;
            //
            //
            // this.scenePostRenderListenerRemover = viewer.scene.postRender.addEventListener(this.updatePopupPosition)

            viewer.scene.frameState.creditDisplay.addDefaultCredit(new Credit('<h4>&nbsp;&nbsp;版权所有 © 中国科学院地理科学与资源研究所 | 备案序号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color:deepskyblue">京ICP备05002838号-59</a></h4>'), true)
        }

        asyncGet(`${SPRING_HOST}/confs/autoZoomLayers.json`, true).then(autoZoomToLayers => {
            this.autoZoomToLayers = new Set(autoZoomToLayers)
        })

    }

    buildCollapsePanel = (data) => {
        console.log(data)
        return data.map((item, index) => <Panel className={"site-collapse-custom-panel"} header={item.layer.name}
                                                key={item.layer.name}
                                                extra={this.genExtra(item, index)}>
            <Row>
                <Col span={24}>
                    <ActiveLayerListItem key={item.layer.name} index={index} checkedLayer={item}/>
                </Col>
            </Row>

        </Panel>)
    }

    genExtra = (item, index) => {
        const that = this
        const commonAction = [
            <DeleteIcon
                title={"移除图层"}
                className={"collapse-icon"}
                onClick={event => {
                    event.stopPropagation();
                    that.props.removeLayer(index)

                }}
                key={"移除图层"}
            />,

            <MoveUpIcon
                title={"置顶图层"}
                className={"collapse-icon"}
                onClick={event => {
                    event.stopPropagation();
                    that.props.raiseAsTopLayer(index)
                }}
                key={"置顶图层"}
            />,
            <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked checked={item.visible} size={"small"}
                    onChange={(checked, event) => {
                        event.stopPropagation();
                        that.props.toggleLayerVisibility(index)
                    }} style={{marginBottom: "7px"}}
                    title={'显示/隐藏：' + item.key}
                    key={"开关"}
            />]


        const drawChart = (<ChartIcon
            className={"collapse-icon"}
            onClick={event => {
                event.stopPropagation();
                // console.log("INDEX:", item.layerStyles[0])
                that.props.toggleBottomDrawer(true)
                that.props.toggleLayerStyle(index, item.layerStyles[0])
                if (this.props.checkedLayers[index].drawEntity && this.props.showGL) {
                    // console.log("FETCH" + item.key)
                    this.props.fetchGLData(item.key)
                } else if (this.props.showGL) {
                    this.props.toggleShowGL(false)
                }

            }}
        />)

        if (item.attachment) {
            commonAction.unshift(<DownloadIcon
                title={"下载图层数据"}
                className={"collapse-icon"}
                onClick={event => {
                    event.stopPropagation();

                    if (item.attachment.accessType === "直接下载") {
                        window.open(item.attachment.attachment)
                    } else {
                        // 显示申请表格
                        this.props.updateAppFields({activeDownloadedLayer: item.attachment})
                    }
                }}
                key={"下载图层数据"}
            />,)
        }


        return item.drawChart ? [drawChart, ...commonAction] : commonAction
    };

    updatePopupPosition = () => {
        if (this.props.popupOpen) {
            const cartesian = this.props.popupCartesian3;
            const camera = this.viewer.scene.camera;
            const ellipsoidBoundingSphere = new Cesium.BoundingSphere(new Cesium.Cartesian3(), 6356752);
            const occlude = new Cesium.Occluder(ellipsoidBoundingSphere, camera.position);
            // 检查弹框是否在地平面以下
            if (this.viewer.scene.mode === SceneMode.SCENE3D && !occlude.isPointVisible(cartesian)) {
                this.props.togglePopup(false);
                return;
            }
            const cullingVolume = camera.frustum.computeCullingVolume(camera.position, camera.direction, camera.up);
            // 检查弹框从相机视角是否可见
            if (this.viewer.scene.mode === SceneMode.SCENE3D && cullingVolume.computeVisibility(new Cesium.BoundingSphere(cartesian)) !== 1) {
                this.props.togglePopup(false);
                return;
            }

            const screenXY = this.viewer.scene.cartesianToCanvasCoordinates(cartesian);
            let siderWidth = 0;

            if (this.props.siderOpen) {
                siderWidth = this.sidePanel.offsetWidth
            }
            if (this.props.popupXY[1] !== screenXY.y) {

                this.props.setPopupXY([screenXY.x + siderWidth, screenXY.y])
            }

        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.viewer) {
            // 比较 checkedLayer 的变化
            // 用于设置 viewer clock 为当前可见的最上层图层的时钟
            let prevTopVisibleLayer = prevProps.topVisibleLayer;
            let curTopVisibleLayer = this.props.topVisibleLayer;
            const viewer = this.viewer;

            //将边界图层置顶
            if (prevTopVisibleLayer === undefined && curTopVisibleLayer !== undefined) {
                this.props.setBoundaryLayerKey(Math.random())
            } else if (prevTopVisibleLayer !== undefined && curTopVisibleLayer !== undefined && prevTopVisibleLayer.layers !== curTopVisibleLayer.layers) {
                this.props.setBoundaryLayerKey(Math.random())
            }

            if (this.props.popupCountry === "中国") {
                this.props.togglePopup(false);
            }

            // const clock = viewer.clock;
            //
            //
            // if (curTopVisibleLayer !== undefined && (prevTopVisibleLayer === undefined || prevTopVisibleLayer.layers !== curTopVisibleLayer.layers) && curTopVisibleLayer.isTimeSeries) {
            //
            //     const start = Cesium.JulianDate.fromIso8601(curTopVisibleLayer.times.get(0).data.Time);
            //     const stop = Cesium.JulianDate.fromIso8601(curTopVisibleLayer.times.get(curTopVisibleLayer.times.length - 1).data.Time);
            //     clock.startTime = start;
            //     clock.stopTime = stop;
            //     clock.currentTime = curTopVisibleLayer.defaultTime || start;
            //     clock.clockRange = Cesium.ClockRange.LOOP_STOP;
            //     clock.multiplier = curTopVisibleLayer.multiplier;
            //     viewer.timeline.zoomTo(start, stop);
            //
            //
            // } else if (curTopVisibleLayer === undefined || !curTopVisibleLayer.isTimeSeries) {
            //
            //     const currentTime = Cesium.JulianDate.now()
            //     const stopTime = Cesium.JulianDate.fromIso8601('2030');
            //     clock.startTime = currentTime;
            //     clock.currentTime = currentTime
            //     clock.stopTime = stopTime
            //     clock.clockRange = Cesium.ClockRange.LOOP_STOP;
            //     clock.multiplier = 1;
            //     viewer.timeline.zoomTo(currentTime, stopTime);
            // }
        }

    }

    onImageryLayersUpdate = () => {


        if (this.viewer) {

            const imageryLayers = this.viewer.imageryLayers
            const topLayer1 = imageryLayers.get(imageryLayers.length - 1)


            const topLayer2 = this.props.checkedLayers.filter(item => item.type !== 'czml').map((ckl, index) => {
                return ckl.layer
            })[0] || {}


            if (topLayer1._imageryProvider._layers === topLayer2._layers) {
                if (this.autoZoomToLayers.has(topLayer1._imageryProvider._layers)) {
                    this.viewer.flyTo(topLayer1)
                }
            }


        }
    }


    onMapClick = (e) => {
        // 触发 popup 更新

        if (this.scenePostRenderListenerRemover) {
            this.scenePostRenderListenerRemover();
        }
        this.scenePostRenderListenerRemover = null;

        const viewer = this.viewer;
        this.scenePostRenderListenerRemover = viewer.scene.postRender.addEventListener(this.updatePopupPosition)

        const camera = viewer.scene.camera;

        const cartesian3 = camera.pickEllipsoid(e.position, viewer.scene.globe.ellipsoid)

        if (cartesian3 === undefined) {
            // 点击非地球区域
            return;
        }

        const screenXY = viewer.scene.cartesianToCanvasCoordinates(cartesian3);
        const lnglat = Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian3)
        const lng = Cesium.Math.toDegrees(lnglat.longitude);
        const lat = Cesium.Math.toDegrees(lnglat.latitude);
        this.props.fetchPopupData(lng, lat)
        this.props.setPopupCartesian3(cartesian3)

        if (this.props.popupType === "chart") {
            this.props.togglePopup(true)
        }

        let siderWidth = 0;
        if (this.props.siderOpen) {
            siderWidth = this.sidePanel.offsetWidth
        }

        const popupX = screenXY.x + siderWidth;
        const popupY = screenXY.y;

        this.props.setPopupXY([popupX, popupY])

        //2022年01月11日 当弹框显示不完整的时候，转动一下地球

        // console.log("x:" + popupX)
        // console.log("y:" + popupY)

        // const earthRadis = 6356752;

        const cameraPosition = camera.position;


        var hRatio = 6378137 / (6378137 + Cesium.Ellipsoid.WGS84.cartesianToCartographic(cameraPosition).height);


        if (popupY < 410) {
            // 顶部溢出
            // console.log("顶部溢出")


            if (screenXY.x <= 150) {
                //
                // console.log("左边溢出")

                this.setState({
                    popupPosition: "Bottom",
                    offset: screenXY.x,
                })
            } else if (popupX + 150 > window.innerWidth) {
                //
                // console.log("右边溢出")

                this.setState({
                    popupPosition: "Bottom",
                    offset: 150 + popupX + 150 - window.innerWidth,
                })

            } else {
                // console.log("顶部溢出2")
                this.setState({
                    popupPosition: "Bottom",
                    offset: 150,
                })
            }


        } else {
            // console.log("底部溢出")
            if (screenXY.x <= 150) {
                // 左边溢出
                // console.log("左边溢出")

                this.setState({
                    popupPosition: "Top",
                    offset: screenXY.x,
                })
            } else if (popupX + 150 > window.innerWidth) {
                // 右边溢出
                // console.log("右边溢出")
                this.setState({
                    popupPosition: "Top",
                    offset: 150 + popupX + 150 - window.innerWidth,
                })

            } else {
                // console.log("未溢出")
                this.setState({
                    popupPosition: "Top",
                    offset: 150,
                })
            }
        }


        //2020年11月26日,对于时序czml,弹出时序柱状图

        if (this.props.topVisibleLayer && this.props.topVisibleLayer.hasChart) {
            // 获取数据并显示 图表区
            let layerKey = this.props.topVisibleLayer.name;

            this.props.fetchLineChartData({
                layerKey,
                lng,
                lat
            })

            this.props.setChartTitle(layerKey)

        }

    }

    submitDownloadRequest = (values) => {
        post(`${SPRING_HOST}/layerData/submitDownloadRequest`, values, {"Authorization": "Bearer " + this.props.accessToken}).then(
            res => {
                if (res) {
                    notification["success"]({
                        message: '申请已提交',
                        description: '审核结果将通过邮箱告知，请留意',
                    });
                } else {
                    notification["error"]({
                        message: '申请提交失败',
                        // description: res.msg,
                    });
                }
            }
        )
    }

    render() {

        if (!this.props.authed) {
            return <Redirect to={"/login"}/>
        }
        // else if (this.props.needChangePassword) {
        //     return <Redirect to={this.props.redirect}/>
        // }

        return (
            <>
                <Modal
                    title="温馨提示"
                    visible={!this.props.remindTomorrow && this.props.needChangePassword}
                    onOk={() => {
                        window.location.href = "/user/profile#changePassword";
                    }}
                    onCancel={() => {
                        console.log(this.props)
                        console.log(this.props.remindTomorrow)
                        this.props.toggleRemindTomorrow()
                    }}
                    cancelText={"今日不再提醒"}
                    okText="立即去修改"
                >
                    <h3>您已经超过90天未修改密码了，请及时修改密码!</h3>
                </Modal>
                <SplitPane split="vertical" size={this.props.siderWidth} minSize={450} defaultSize={450} maxSize={600}
                           style={{height: '100vh'}} allowResize={this.props.siderOpen}>
                    <div className={"side-pane"} id={"sidePanel"} ref={e => this.sidePanel = e}>
                        <Tooltip placement="left" title={"隐藏工作台"}>
                            <DoubleLeftIcon onClick={() => this.props.toggleSider(false)}
                                            className={this.props.siderOpen ? "show" : "hide"} style={{
                                zIndex: 1000,
                                cursor: "pointer",
                                fontSize: "24px",
                                position: "absolute",
                                top: "120px",
                                right: "0"
                            }}/></Tooltip>
                        <div className={"title-bar"}/>
                        <SplitPane className={"catalog-split"} split="horizontal" minSize={300} defaultSize={500}
                                   maxSize={600}
                                   style={{position: 'relative', height: "calc(100vh - 100px)"}} primary={'first'}
                                   allowResize={true}
                                   pane2Style={{overflowY: "scroll"}}
                        >
                            <Pane className={"upper-pane"}>
                                <Row justify={"center"} style={{
                                    height: "100%"
                                }}>
                                    <Col span={24} style={{
                                        height: "100%"
                                    }}>
                                        <Tabs className={"xx"} tabPosition={"top"} size={"large"}
                                              tabBarStyle={{paddingLeft: "10px"}}
                                              style={{
                                                  height: "100%"
                                              }}>

                                            <TabPane
                                                className={"tab-pane"}
                                                tab={
                                                    <span>
                                                <DashboardOutlined/>
                                                    风险评估
                                                </span>} key="2">
                                                <div>
                                                    <RiskEvaluateTab/>
                                                </div>

                                            </TabPane>

                                            <TabPane
                                                className={"tab-pane"}
                                                tab={<span>
                                                <FundOutlined/>
                                                    项目监测
                                    </span>} key="3">
                                                <div style={{overflowY: 'auto'}}>
                                                    <ProjectMonitorTab/>
                                                </div>

                                            </TabPane>

                                            <TabPane
                                                className={"tab-pane"}
                                                tab={
                                                    <span>
                                    <UnorderedListOutlined/>
                                        基础信息
                                    </span>
                                                } key="1">
                                                <BasicDataTab/>
                                            </TabPane>

                                        </Tabs>
                                    </Col>
                                </Row>
                            </Pane>
                            <Pane style={{overflowY: "scroll"}} minSize={200} maxSize={800}>
                                <Row justify={"center"}>
                                    <Col span={24}>
                                        {this.props.checkedLayers.length === 0 ?
                                            <Empty description={"暂无活动图层"}/> :
                                            <Collapse className="site-collapse-custom-collapse" bordered={false}
                                                      defaultActiveKey={[this.props.checkedLayers.length > 0 ? this.props.checkedLayers[0].layer.name : "0"]}
                                                      key={this.props.checkedLayers.length}>
                                                {this.buildCollapsePanel(this.props.checkedLayers)}
                                            </Collapse>}

                                    </Col>
                                </Row>
                            </Pane>
                        </SplitPane>


                    </div>
                    <div id='contentContainer' style={{height: "100%"}}>

                        <Tooltip placement="left" title={"工作台"}>
                            <DoubleRightIcon onClick={() => this.props.toggleSider(true)}
                                             className={this.props.siderOpen ? "hide" : "show"} style={{
                                zIndex: 1000,
                                cursor: "pointer",
                                fontSize: "24px",
                                position: "absolute",
                                left: "0",
                                top: "120px"
                            }}/>
                        </Tooltip>

                        {/*<Radio.Group onChange={e => this.props.togglePopupType(e.target.value)} buttonStyle="solid"*/}
                        {/*             value={this.props.popupType} size={"small"}*/}
                        {/*             className={this.props.popupOpen ? "show" : "hide"}*/}
                        {/*             style={{*/}
                        {/*                 marginLeft: "40%",*/}
                        {/*                 marginTop: "10px"*/}
                        {/*             }}*/}
                        {/*>*/}
                        {/*    <Radio.Button value="chart" style={{zIndex: 1001, border: "none"}}>综合评价</Radio.Button>*/}
                        {/*    <Radio.Button value="doc" disabled={!this.props.hasCountryInfo}*/}
                        {/*                  style={{zIndex: 1001, border: "none"}}>国别情况</Radio.Button>*/}
                        {/*</Radio.Group>*/}

                        <div className={"welcome-info"}>
                            <a href={"/user/profile"} title={"用户信息"}>{"欢迎：" + this.props.name}</a>
                            <Button shape={"circle"} size={"large"} type={"link"} title={"退出"}
                                    icon={<LogoutOutlined/>} style={{display: "inline-block"}}
                                    onClick={() => this.props.doLogout()}> </Button>
                        </div>

                        {this.props.mapProvider === 'mapbox' && <GedViewer/>}

                        {this.props.mapProvider === 'mapbox_fullTime' && <GedFullTimeViewer/>}

                        {this.props.mapProvider === 'cesium' && <Viewer full={true}
                                                                        animation={true}
                                                                        baseLayerPicker={true}
                                                                        fullscreenButton={true}
                                                                        vrButton={false}
                                                                        geocoder={CustomGeocoderService}
                                                                        homeButton={true}
                                                                        infoBox={false}
                                                                        sceneModePicker={true}
                                                                        selectionIndicator={false}
                                                                        timeline={true}
                                                                        navigationHelpButton={false}
                                                                        navigationInstructionsInitiallyVisible={true}
                                                                        scene3DOnly={false}
                                                                        shouldAnimate={false}
                                                                        imageryProviderViewModels={baseLayers}
                                                                        terrainProviderViewModels={terrainLayers}
                                                                        ref={e => {
                                                                            this.viewer = e && e.cesiumElement;
                                                                        }}
                                                                        onClick={this.onMapClick}
                        >
                            <Globe onImageryLayersUpdate={this.onImageryLayersUpdate}/>

                            {(this.props.checkedLayers.filter(item => item.type !== 'czml').map((ckl, index) => {
                                return <ImageryLayer imageryProvider={ckl.layer} key={index}
                                                     show={ckl.visible}
                                />
                            })).reverse()}

                            <ImageryLayer imageryProvider={boundryLayer} key={this.props.boundaryLayerKey}/>

                            {(this.props.checkedLayers.filter(item => item.type === 'czml').map((ckl, index) => {
                                return <CzmlDataSource data={ckl.layer.url} show={true} key={index}/>
                            }))}

                            <ReWebGLGlobeDataSource
                                shaders={this.props.shaderMap.get(this.props.chartLayer.key)}
                                data={this.props.glData}
                                show={this.props.showGL && this.props.chartLayer.visible}
                                // show={this.props.showGL && this.props.checkedLayers.filter(lr=>lr.key===this.props.chartLayer.key).length>0 && this.props.chartLayer.visible}
                                seriesToDisplay={this.props.chartLayer.layerStyles && this.props.chartLayer.layerStyles[0]}/>

                        </Viewer>}

                        {
                            this.props.topVisibleLayer && (this.props.topVisibleLayer.isTimeSeries || this.props.checkedLayers.find(item => item.type === 'czml'))
                                ? <div
                                    style={{width: '100%', position: "absolute", bottom: "0px", background: "#3f485499"}}>
                                    <Timeline/></div>
                                : null
                        }

                        <Drawer
                            title="一带一路国家发展综合评价(148国)"
                            className={"chart-drawer all-radar"}
                            placement="bottom"
                            height={800}
                            closable={true}
                            mask={false}
                            maskClosable={false}
                            onClose={this.props.toggleAllRadarDrawer}
                            visible={this.props.allRadarDrawerOpen}
                            getContainer={false}
                            style={{position: 'absolute'}}
                        >
                            <List

                                grid={{gutter: 16, md: 1, lg: 2, xl: 4, xxl: 4}}
                                dataSource={this.props.allRadarData}
                                renderItem={item => (
                                    <List.Item>
                                        <Radar height={270} width={270} data={item}
                                               options={{
                                                   ...options,
                                                   title: {...options.title, text: item.datasets[0].label}
                                               }}/>

                                    </List.Item>
                                )}
                            />
                        </Drawer>
                    </div>
                </SplitPane>
                <ChartDrawer/>
                <LineChartDrawer chartTitle={this.props.chartTitle + "_" + this.props.popupCountry}
                                 chartData={this.props.lineChartData} visible={this.props.lineChartDrawerOpen}
                                 toggleLineChartDrawer={this.props.toggleLineChartDrawer}/>
                <ResponsivePopup pos={this.state.popupPosition} off={this.state.offset}/>

                <Modal
                    className={"layer-info-modal"}
                    title={<span><DocIcon/>{this.props.popupCountry + "_国家治理结构"}</span>}
                    closable={true}
                    mask={false}
                    maskClosable={false}
                    getContainer={document.getElementById("contentContainer")}
                    width={document.body.clientWidth - 750}
                    bodyStyle={{backgroundColor: "#fff"}}
                    onCancel={e => this.props.togglePopup(false)}
                    visible={this.props.popupOpen && this.props.hasCountryInfo && this.props.popupType === "doc"}
                    footer={false}
                    style={{marginLeft: '600px'}}
                >
                    <iframe
                        style={{width: '100%', overflow: 'hidden'}}
                        src={`${SPRING_HOST}/poster/${this.props.popupCountry}.html`}
                        width="100%"
                        height={"680px"}
                        frameBorder="0"
                    />
                </Modal>

                <Modal
                    className={"layer-download-apply-modal"}
                    title={"数据下载申请: " + (this.props.activeDownloadedLayer ? this.props.activeDownloadedLayer.layerName : "")}
                    closable={true}
                    mask={true}
                    maskClosable={false}
                    width={640}
                    bodyStyle={{backgroundColor: "#272727"}}
                    onCancel={e => this.props.updateAppFields({
                        activeDownloadedLayer: undefined
                    })}
                    visible={this.props.activeDownloadedLayer}
                    footer={<p style={{
                        textAlign: "right"
                    }}>注：使用数据，请标注来源为"中科院绿色丝绸之路决策支持系统(https://www.brisupport.cn)"</p>}
                    // style={{marginLeft: '600px'}}
                >
                    <Form
                        labelCol={{span: 6}}
                        wrapperCol={{span: 18}}
                        name="nest-messages" onFinish={
                        this.submitDownloadRequest
                    }
                        initialValues={this.props.activeDownloadedLayer}
                    >

                        <Form.Item name="layerId" hidden={true}>
                            <Input/>
                        </Form.Item>

                        <Form.Item name="userName" label="申请人" rules={[{required: true}]}>
                            <Input type={"text"} maxLength={10}/>
                        </Form.Item>

                        <Form.Item name="userEmail" label="邮箱" rules={[{required: true}]}>
                            <Input type={"email"}/>
                        </Form.Item>

                        <Form.Item name="userTel" label="电话" rules={[{required: true}]}>
                            <Input type={"text"} maxLength={11}/>
                        </Form.Item>

                        <Form.Item name="userOrg" label="工作单位" rules={[{required: true}]}>
                            <Input type={"text"} maxLength={11}/>
                        </Form.Item>
                        <Form.Item name="reason" label="数据用途" rules={[{required: true}]}>
                            <Input type={"text"} maxLength={200}/>
                        </Form.Item>

                        <Form.Item wrapperCol={{span: 18, offset: 6}}>
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}


const mapStateToProps = (state, props) => {
    return {

        name: (state => state.login.name)(state),
        authed: (state => state.login.authed)(state),
        shaderMap: (state => state.layers.shaderMap)(state),
        glData: (state => state.layers.glData)(state),
        showGL: (state => state.layers.showGL)(state),
        chartLayer: (state => state.layers.chartLayer)(state),


        popupType: getPopupType(state),
        hasCountryInfo: getHasCountryInfo(state),
        boundaryLayerKey: getBoundaryLayerKey(state),
        popupOpen: getPopupOpen(state),
        popupCountry: getPopupCountry(state),
        popupXY: getPopupXY(state),
        popupCartesian3: getPopupCartesian3(state),
        popupData: getPopupData(state),
        topVisibleLayer: getTopVisibleLayer(state),
        chartData: getChartData(state),
        bottomDrawerOpen: getBottomDrawerOpen(state),
        siderOpen: getSiderOpen(state),
        siderWidth: getSiderWidth(state),
        checkedLayers: getCheckedLayers(state),
        layerMap: getLayerMap(state),
        expandedNodes: getExpandedNodes(state),
        checkedNodes: getCheckedNodes(state),
        autoExpandParent: getAutoExpandParent(state),
        layerSearchText: getLayerSearchText(state),
        layerTreeData: getLayerTreeData(state),
        mapProvider: getMapProvider(state),
        allRadarData: ((state) => state.app.allRadarData)(state),
        allRadarDrawerOpen: ((state) => state.app.allRadarDrawerOpen)(state),
        lineChartDrawerOpen: ((state) => state.layers.lineChartDrawerOpen)(state),
        lineChartData: ((state) => state.layers.lineChartData)(state),
        chartTitle: ((state) => state.layers.chartTitle)(state),
        activeDownloadedLayer: ((state) => state.app.activeDownloadedLayer)(state),
        accessToken: (state => state.login.accessToken)(state),
        redirect: (state => state.login.redirect)(state),
        shouldRedirect: (state => state.login.shouldRedirect)(state),
        needChangePassword: (state => state.login.needChangePassword)(state),
        remindTomorrow: (state => state.login.remindTomorrow)(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(appActions, dispatch),
        ...bindActionCreators(layerActions, dispatch),
        ...bindActionCreators(loginActions, dispatch)
    };
};

App.childContextTypes = {
    zoomTo: PropTypes.func
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(App));



