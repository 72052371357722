import {WebMapServiceImageryProvider, defined} from 'cesium'

/**
 * 扩展内部实现，增加编号，关联图表功能
 */

// 透明png
const defaultParameters = {...WebMapServiceImageryProvider.DefaultParameters, format: 'image/png', transparent: true}

class CustomWMSImageryProvider extends WebMapServiceImageryProvider {
    constructor(options) {
        // 增加wms默认自定义参数

        const {extraInfo, absentSeries, waitTime, drawChart, multiplier, alias, style, customLegend, legendDpi, drawEntity, shaders, defaultTime} = options

        if (style) {
            options = {...options, parameters: {...defaultParameters, styles: style}}

        } else {
            options = {...options, parameters: defaultParameters}
        }

        super(options);

        this.type = 'wms'

        this.style = style

        this.name = alias || this.layers

        // 本图层是否关联图表
        this.drawChart = drawChart || false

        this.extraInfo = extraInfo || false

        this.shader = shaders

        this.drawEntity = drawEntity || false

        this.customLegend = customLegend || false

        this.legendDpi = legendDpi || 200;


        this.isTimeSeries = defined(this._timeDynamicImagery)

        if (this.isTimeSeries) {
            this.absentSeries = new Set(absentSeries||[])
            this.waitTime = waitTime || 5000
            this.multiplier = multiplier
            this.defaultTime = defaultTime
        }

    }

}

export default CustomWMSImageryProvider
