import { combineReducers } from "redux";
import app from './app';
import layers from './layers';
import login from './login'
import register from './register'

const rootReducer = combineReducers({
  register,
  login,
  app,
  layers,
});

export default rootReducer;
