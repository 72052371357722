import {
    ProviderViewModel,
    UrlTemplateImageryProvider,
    WebMapServiceImageryProvider,
} from "cesium";

import google_img from '../assets/images/google_img.png'
import google_map from '../assets/images/google_map.png'
import shp_map from '../assets/images/shp_map.png'


import {WMS_URL} from "../components/utils";

const baseLayers = [

    new ProviderViewModel({
        name: "影像地图",
        tooltip: "ESRI影像地图",
        iconUrl: google_img,
        creationFunction: () => new UrlTemplateImageryProvider({
            url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"

        })

    }),
    new ProviderViewModel({
        name: "标准地图",
        tooltip: "标准地图",
        iconUrl: google_map,
        creationFunction: () => new UrlTemplateImageryProvider({
            url: "https://map.geoq.cn/arcgis/rest/services/ChinaOnlineCommunity/MapServer/tile/{z}/{y}/{x}"
        })

    }),
    // new ProviderViewModel({
    //     name: "天地图",
    //     tooltip: "天地图影像",
    //     iconUrl: tianditu_img,
    //     creationFunction: () => new WebMapTileServiceImageryProvider({
    //         url: "http://t0.tianditu.com/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=19b72f6cde5c8b49cf21ea2bb4c5b21e",
    //         layer: "tdtBasicLayer",
    //         style: "default",
    //         format: "image/jpeg",
    //         maximumLevel: 18, //天地图的最大缩放级别
    //         tileMatrixSetID: "GoogleMapsCompatible",
    //         show: false
    //     })
    // }),

    // new ProviderViewModel({
    //     name: "ESRI",
    //     tooltip: "ESRI影像",
    //     iconUrl: esri_img,
    //     creationFunction: () => new ArcGisMapServerImageryProvider({
    //         url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer'
    //         , baseLayerPicker: false
    //     })
    //
    // }),

    new ProviderViewModel({
        name: "纯色底图",
        tooltip: "纯色边界底图",
        iconUrl: shp_map,
        creationFunction: () => new WebMapServiceImageryProvider({
            url: WMS_URL,
            layers: "世界国家",
            baseLayerPicker: false
        })
    })
]

export default baseLayers;




