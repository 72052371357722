/*
 * Created at 2021/3/22 下午8:27 by lhq
 */

import './UserProfile.css'
import React, {Component} from "react";
import {Button, Col, Descriptions, Form, Input, Layout, notification, Row, Tabs} from 'antd';
import {hot} from "react-hot-loader/root";
import {loginActions} from "../../redux/modules/login";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link, Redirect} from 'react-router-dom'
import {get, post, SPRING_HOST} from "../utils";

const {Header, Sider, Content, Footer} = Layout;

const {TabPane} = Tabs;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 18,
        },
    },
};


const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 18,
            offset: 6,
        },
    },
};

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTabKey: "1",
            userInfo: {createTime: "yyyy-MM-dd"}
        }
    }

    editUserInfoFormRef = React.createRef();
    changePasswordFromRef = React.createRef();

    componentWillMount() {
        this.props.setRedirect("/user/profile")
        this.props.getAuthState();
    }


    getProfile = () => {
        get(`${SPRING_HOST}/user/getProfile`, {"Authorization": "bearer " + this.props.accessToken}).then(
            res => {
                console.log(res)
                if (res.code === 200) {
                    this.setState({
                        userInfo: res.data
                    })

                    if (this.editUserInfoFormRef.current) {
                        this.editUserInfoFormRef.current.setFieldsValue({
                            "name": res.data.name,
                            "username": res.data.username
                        })
                    }

                }

            }
        )
    }

    componentDidMount() {
        this.getProfile()
        console.log(window.location.href)
        if (window.location.href.indexOf("#changePassword") !== -1) {
            this.setState({
                activeTabKey: "changePassword"
            })
        }
    }

    updateUsername = (values) => {
        let userDto = {
            'name': values.name,
            'username': values.username
        }

        post(`${SPRING_HOST}/user/updateUsername`, userDto, {"Authorization": "Bearer " + this.props.accessToken}).then(
            res => {

                if (res.data) {
                    notification["success"]({
                        message: '修改成功',
                        description: '修改成功，重新登录生效',
                    });

                    setTimeout(() => {
                        window.location.href = window.location.href.replace("register", "login")
                    }, 5000);

                } else {
                    notification["error"]({
                        message: '修改失败',
                        description:
                        res.msg,
                    });
                }
            }
        )
    }

    onChangePassword = (values) => {

        let changePasswordDto = {
            "oldPassword": values.oldPassword,
            "newPassword": values.newPassword
        }

        post(`${SPRING_HOST}/user/changePassword`, changePasswordDto, {"Authorization": "Bearer " + this.props.accessToken}).then(
            res => {
                if (res.data) {
                    notification["success"]({
                        message: '修改成功',
                        description: '密码已修改',
                    });

                    // localStorage.removeItem("ydyl.loginStorage")
                } else {
                    notification["error"]({
                        message: '修改失败',
                        description: res.msg,
                    });
                }
            }
        )

    }


    render() {

        if (!this.props.authed) {
            return <Redirect to={"/login"}/>
        }
        return (
            <Layout className={"profile-root"}>
                <Header className="header">
                    <Link to={"/"} className={"logo"}/>
                    <div className={"header-title"}>账号信息</div>
                    <div className={"welcome"}><Link to={"/"}>回首页</Link></div>
                </Header>

                <Layout className={"site-layout-background"} style={{padding: '0 24px 24px'}}>
                    <Content

                        style={{
                            padding: 24,
                            margin: 0,
                        }}
                    >
                        <Tabs tabPosition={"left"} onChange={key => {
                            this.setState({activeTabKey: key})
                            if (key === "2") {
                                this.getProfile()
                            }
                        }} activeKey={this.state.activeTabKey}>
                            <TabPane tab="用户信息" key="1">
                                <Row>
                                    <Col span={8} offset={1}>
                                        <Descriptions title="用户基本信息" column={1} bordered>
                                            <Descriptions.Item label="昵称">{this.state.userInfo.name}</Descriptions.Item>
                                            <Descriptions.Item
                                                label="用户名">{this.state.userInfo.username}</Descriptions.Item>
                                            <Descriptions.Item
                                                label="注册邮箱">{this.state.userInfo.email}</Descriptions.Item>
                                            <Descriptions.Item
                                                label="注册日期">{this.state.userInfo.createTime.substr(0, 10)}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>

                            </TabPane>
                            <TabPane tab="修改信息" key="2">
                                <Row>
                                    <Col span={8}>
                                        <Form
                                            {...formItemLayout}
                                            ref={this.editUserInfoFormRef}

                                            name="register"
                                            onFinish={this.updateUsername}
                                            scrollToFirstError
                                        >

                                            <Form.Item
                                                name="name"
                                                label="昵称"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '昵称不能为空',
                                                    },
                                                ]}
                                            >
                                                <Input defaultValue={this.state.name}/>
                                            </Form.Item>

                                            <Form.Item
                                                name="username"
                                                label="用户名"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '用户名不能为空',
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input/>
                                            </Form.Item>


                                            <Form.Item {...tailFormItemLayout}>
                                                <Button type="primary" htmlType="submit" style={{
                                                    marginRight: "8px"
                                                }}>
                                                    提交
                                                </Button>
                                                <Button>
                                                    取消
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="修改密码" key="changePassword">
                                <Row>
                                    <Col span={8}>
                                        <Form
                                            {...formItemLayout}
                                            ref={this.changePasswordFromRef}

                                            name="register"
                                            onFinish={this.onChangePassword}
                                            scrollToFirstError
                                        >

                                            <Form.Item
                                                name="oldPassword"
                                                label="旧密码"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入密码',
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password/>
                                            </Form.Item>

                                            <Form.Item
                                                name="newPassword"
                                                label="新密码"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入密码',
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password/>
                                            </Form.Item>

                                            <Form.Item
                                                name="confirm"
                                                label="确认新密码"
                                                dependencies={['newPassword']}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请确认密码',
                                                    },
                                                    ({getFieldValue}) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('newPassword') === value) {
                                                                return Promise.resolve();
                                                            }

                                                            return Promise.reject(new Error('两次新密码输入不一致!'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password/>
                                            </Form.Item>


                                            <Form.Item {...tailFormItemLayout}>
                                                <Button type="primary" htmlType="submit"
                                                        style={{"color": "grey"}}>
                                                    提交

                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane>

                        </Tabs>

                    </Content>
                </Layout>
            </Layout>
        )
    }

}

const mapStateToProps = (state, props) => {
    return {
        authed: (state => state.login.authed)(state),
        accessToken: (state => state.login.accessToken)(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {

        ...bindActionCreators(loginActions, dispatch)
    }
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
