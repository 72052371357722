/*
 * Created at 2021/11/26 上午7:41 by lhq
 */

import './GedViewer.css';
import React, {Component} from 'react';
import MapGL, {
    Source,
    Layer
} from 'react-map-gl';
import moment from "moment";
import RangeInput from "../widget/RangeInput";
import {SPRING_HOST} from "../utils";

import {MapboxStyleSwitcherControl} from "mapbox-gl-style-switcher";
import "mapbox-gl-style-switcher/styles.css";
import LegendPanel from "../widget/LegendPanel";

const canvasSize = 500;

const styles = [
    {
        title: "深色地图",
        uri: `${SPRING_HOST}/geoq-dark.json`
    },
    {
        title: "浅色地图",
        uri: `${SPRING_HOST}/geoq-light.json`
    },
    {
        title: "标准地图",
        uri: `${SPRING_HOST}/amap.json`
    },
    {
        title: "卫星地图",
        uri: `${SPRING_HOST}/image.json`
    }
];

const styleControl = new MapboxStyleSwitcherControl(styles, '标准地图');


function Particle(x, y, xs, ys) {
    this.x = x;
    this.y = y;
    this.xs = xs;
    this.ys = ys;
    this.life = 0;
}

const particles = [];

function update(stage, mouseX, mouseY, speed = 3, size = 30, max = 60) {

    //Adds ten new particles every frame
    for (var i = 0; i < 10; i++) {

        //Adds a particle at the mouse position, with random horizontal and vertical speeds
        var p = new Particle(mouseX, mouseY, (Math.random() * 2 * speed - speed) / 2, 0 - Math.random() * 2 * speed);
        particles.push(p);
    }

    //Clear the stage so we can draw the new frame
    stage.clearRect(0, 0, canvasSize, canvasSize);

    //Cycle through all the particles to draw them
    for (i = 0; i < particles.length; i++) {

        //Set the file colour to an RGBA value where it starts off red-orange, but progressively gets more grey and transparent the longer the particle has been alive for
        stage.fillStyle = "rgba(" + (260 - (particles[i].life * 2)) + "," + ((particles[i].life * 2) + 50) + "," + (particles[i].life * 2) + "," + (((max - particles[i].life) / max) * 0.4) + ")";

        stage.beginPath();
        //Draw the particle as a circle, which gets slightly smaller the longer it's been alive for
        stage.arc(particles[i].x, particles[i].y, (max - particles[i].life) / max * (size / 2) + (size / 2), 0, 2 * Math.PI);
        stage.fill();

        //Move the particle based on its horizontal and vertical speeds
        particles[i].x += particles[i].xs;
        particles[i].y += particles[i].ys;

        particles[i].life++;
        //If the particle has lived longer than we are allowing, remove it from the array.
        if (particles[i].life >= max) {
            particles.splice(i, 1);
            i--;
        }
    }
}

class GedViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            currentTime: moment("1989-01-01"),
            hoverInfo: null,
            popupInfo: null,
            viewport: {
                latitude: 30.832703,
                longitude: 35.559081,
                minZoom: 2,
                zoom: 2
            },
            layer: "grid",
        }
    }


    componentDidMount() {

        let map = undefined;

        if (this.mapRef) {
            map = this.mapRef.getMap();
            map.addControl(styleControl, 'top-right');

        }

        if (map) {

            const pulsingDot = {
                width: canvasSize,
                height: canvasSize,
                data: new Uint8Array(canvasSize * canvasSize * 4),

                // When the layer is added to the map,
                // get the rendering context for the map canvas.
                onAdd: function () {
                    const canvas = document.createElement('canvas');
                    canvas.width = this.width;
                    canvas.height = this.height;
                    this.context = canvas.getContext('2d');
                    canvas.width = canvasSize;
                    canvas.height = canvasSize;
                    canvas.style.width = canvasSize + "px";
                    canvas.style.height = canvasSize + "px";

                    // stage.globalCompositeOperation = "lighter"
                },

                // Call once before every frame where the icon will be used.
                render: function () {

                    const stage = this.context;

                    stage.globalCompositeOperation = "lighter";

                    update(stage, canvasSize/2, canvasSize*0.8)

                    // var timer=setInterval(()=>{
                    //
                    // },40);

                    // Update this image's data with data from the canvas.
                    this.data = stage.getImageData(
                        0,
                        0,
                        this.width,
                        this.height
                    ).data;

                    // Continuously repaint the map, resulting
                    // in the smooth animation of the dot.
                    map.triggerRepaint();

                    // Return `true` to let the map know that the image was updated.
                    return true;
                }
            };

            if (!map.hasImage('map-pin')) {

                map.addImage('pulsing-dot', pulsingDot, {pixelRatio: 2});
            }

        }

    }


    render() {
        return (
            <div className={"mapPanel"}>
                <MapGL
                    ref={(ref) => this.mapRef = ref}
                    {...this.state.viewport}
                    width="100%"
                    height="100vh"
                    // mapboxApiAccessToken={"pk.eyJ1IjoibGhxIiwiYSI6ImNqeXNtc3AyeTBsdjczcG83bGlqaGhmemkifQ.s6_0cniYGuwv3dT4ahGwsA"}
                    mapStyle={`${SPRING_HOST}/dark.json`}
                    onViewportChange={(viewport) => this.setState({viewport})}

                    // interactiveLayerIds={this.props.activeLayer && [this.props.activeLayer]}

                    onHover={(event) => {
                        const {
                            features,
                            srcEvent: {offsetX, offsetY}
                        } = event;
                        const hoveredFeature = features && features[0];

                        if (hoveredFeature) {
                            this.setState({
                                hoverInfo: {
                                    feature: hoveredFeature,
                                    x: offsetX,
                                    y: offsetY
                                }
                            })
                        } else {
                            this.setState({
                                hoverInfo: null
                            })
                        }
                    }}
                >


                    {this.state.layer === 'heatmap' &&
                    <Source id={"heatmap"} type='vector'
                            tiles={[`${SPRING_HOST}/ged/{z}/{x}/{y}?t=${moment(this.state.currentTime).format("YYYY-MM")}`]}>

                        <Layer id={"asdfasd"} {...{
                            'type': 'heatmap',
                            'source-layer': 'hits',
                            "transition": {
                                "duration": 1000,
                                "delay": 0
                            },
                            'paint': {
                                // Increase the heatmap weight based on frequency and property magnitude
                                'heatmap-weight': 1,
                                // Increase the heatmap color weight weight by zoom level
                                // heatmap-intensity is a multiplier on top of heatmap-weight
                                'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
                                // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                                // Begin color ramp at 0-stop with a 0-transparancy color
                                // to create a blur-like effect.
                                'heatmap-color': [
                                    'interpolate',
                                    ['linear'],
                                    ['heatmap-density'],
                                    0,
                                    'rgba(33,102,172,0)',
                                    0.2,
                                    'rgb(103,169,207)',
                                    0.4,
                                    'rgb(209,229,240)',
                                    0.6,
                                    'rgb(253,219,199)',
                                    0.8,
                                    'rgb(239,138,98)',
                                    0.9,
                                    'rgb(178,24,43)'
                                ],
                                // Adjust the heatmap radius by zoom level
                                'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 3, 13, 15],
                                // Transition from heatmap to circle layer by zoom level
                                'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 13, 0]
                            }


                        }} />
                        <Layer id={"asdfxxgv"} {...{
                            'type': 'symbol',
                            'source-layer': 'hits',
                            'minzoom': 3,
                            'layout': {
                                'icon-image': 'pulsing-dot',
                                "icon-size":1,

                            },
                            // 'paint': {
                            //     'icon-opacity': [
                            //         'interpolate',
                            //         ['linear'],
                            //         ['zoom'],
                            //         6,
                            //         0,
                            //         10,
                            //         1]
                            // }
                        }}/>

                    </Source>}
                    {this.state.layer === "grid" &&
                    <Source id={"grid"} type='vector'
                            tiles={[`${SPRING_HOST}/ged/{z}/{x}/{y}?t=` + moment(this.state.currentTime).format("YYYY-MM")]}>

                        <Layer id={"grid"} {...{
                            type: 'fill',
                            'source-layer': 'aggs',
                            paint: {
                                'fill-color': [
                                    'step',
                                    ['get', '_count'],
                                    '#ffebd6', 10,
                                    '#f2c5a6', 20,
                                    '#e69a7a', 40,
                                    '#db6e51', 60,
                                    '#cf3c2c', 80,
                                    '#c40a0b'

                                ],
                                'fill-outline-color': '#575757',
                                'fill-opacity': 0.8
                            }
                        }}/>


                        {
                            this.state.hoverInfo && (<div className="tooltip" style={{
                                left: this.state.hoverInfo.x,
                                top: this.state.hoverInfo.y
                            }}>
                                <div>数量: {this.state.hoverInfo.feature.properties._count}</div>
                            </div>)
                        }


                    </Source>
                    }


                </MapGL>
                <RangeInput
                    min={moment('1989-01-01')}
                    max={moment('2019-12-31')}
                    value={this.state.currentTime}
                    animationSpeed={3e7}
                    formatLabel={(t) => moment(t).format('YYYY-MM')}
                    onChange={(currentTime) => {

                        this.setState({
                            currentTime
                        })
                    }}
                />
                <LegendPanel value={this.state.layer} onChange={(event) => {

                    this.setState({layer: event.target.value})
                }}
                             items={[[
                                 {"color": "rgb(103,169,207)", "label": "(0.2,0.4]"},
                                 {"color": "rgb(209,229,240)", "label": "(0.4,0.6]"},
                                 {"color": "rgb(253,219,199)", "label": "(0.6,0.8]"},
                                 {"color": "rgb(239,138,98)", "label": "(-0.8,0.9]"},
                                 {"color": "rgb(178,24,43)", "label": "(0.9,1.0]"}

                             ], [{"color": "#ffebd6", "label": "(0,10]"},
                                 {"color": "#f2c5a6", "label": "(11,20]"},
                                 {"color": "#e69a7a", "label": "(21,40]"},
                                 {"color": "#db6e51", "label": "(41,60]"},
                                 {"color": "#cf3c2c", "label": "(61,80]"},
                                 {"color": "#c40a0b", "label": "(80-"}


                             ]]}/>
            </div>
        )
    }
}

export default GedViewer;
