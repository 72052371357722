
class CustomCzmlProvider {
    constructor(name,url,timelineTic='yyyy',hasChart=true) {
        this.name = name;
        this.type = 'czml';
        this.url = url;
        this.customLegend = true;
        this.timelineTic = timelineTic
        this.hasChart = hasChart
    }
}

export default CustomCzmlProvider;
