/*
 * Created at 2020/5/3 下午8:11
 */

import * as Cesium from 'cesium'

const defaultShader = height => Cesium.Color.fromHsl(0.6 - height * 0.5, 1.0, 0.5)

function WebGLGlobeDataSource(name, shaders) {

    this.shaders = shaders;
    this._name = name;
    this._show = false;
    this._changed = new Cesium.Event();
    this._error = new Cesium.Event();
    this._isLoading = false;
    this._loading = new Cesium.Event();
    this._entityCollection = new Cesium.EntityCollection();
    this._seriesNames = [];
    this._seriesToDisplay = undefined;
    this._heightScale = 2000000;
    this._entityCluster = new Cesium.EntityCluster();
}

Object.defineProperties(WebGLGlobeDataSource.prototype, {
    //The below properties must be implemented by all DataSource instances

    /**
     * Gets a human-readable name for this instance.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {String}
     */
    name: {
        get: function () {
            return this._name;
        },
    },
    /**
     * Since WebGL Globe JSON is not time-dynamic, this property is always undefined.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {DataSourceClock}
     */
    clock: {
        value: undefined,
        writable: false,
    },
    /**
     * Gets the collection of Entity instances.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {EntityCollection}
     */
    entities: {
        get: function () {
            return this._entityCollection;
        },
    },
    /**
     * Gets a value indicating if the data source is currently loading data.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {Boolean}
     */
    isLoading: {
        get: function () {
            return this._isLoading;
        },
    },
    /**
     * Gets an event that will be raised when the underlying data changes.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {Event}
     */
    changedEvent: {
        get: function () {
            return this._changed;
        },
    },
    /**
     * Gets an event that will be raised if an error is encountered during
     * processing.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {Event}
     */
    errorEvent: {
        get: function () {
            return this._error;
        },
    },
    /**
     * Gets an event that will be raised when the data source either starts or
     * stops loading.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {Event}
     */
    loadingEvent: {
        get: function () {
            return this._loading;
        },
    },

    //These properties are specific to this DataSource.

    /**
     * Gets the array of series names.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {String[]}
     */
    seriesNames: {
        get: function () {
            return this._seriesNames;
        },
    },
    /**
     * Gets or sets the name of the series to display.  WebGL JSON is designed
     * so that only one series is viewed at a time.  Valid values are defined
     * in the seriesNames property.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {String}
     */
    seriesToDisplay: {
        get: function () {
            return this._seriesToDisplay;
        },
        set: function (value) {
            this._seriesToDisplay = value;

            //Iterate over all entities and set their show property
            //to true only if they are part of the current series.
            var collection = this._entityCollection;
            var entities = collection.values;
            collection.suspendEvents();
            for (var i = 0; i < entities.length; i++) {
                var entity = entities[i];
                entity.show = (this._show && value === entity.seriesName);
            }
            collection.resumeEvents();
        },
    },
    /**
     * Gets or sets the scale factor applied to the height of each line.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {Number}
     */
    heightScale: {
        get: function () {
            return this._heightScale;
        },
        set: function (value) {
            if (value > 0) {
                throw new Cesium.DeveloperError("value must be greater than 0");
            }
            this._heightScale = value;
        },
    },
    /**
     * Gets whether or not this data source should be displayed.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {Boolean}
     */
    show: {

        get: function () {

            return this._show;
        },
        set: function (value) {

            this._show = value
            var collection = this._entityCollection;
            var entities = collection.values;
            collection.suspendEvents();
            for (var i = 0; i < entities.length; i++) {
                var entity = entities[i];
                entity.show = (value && this.seriesToDisplay === entity.seriesName);
            }
            collection.resumeEvents();
        },
    },
    /**
     * Gets or sets the clustering options for this data source. This object can be shared between multiple data sources.
     * @memberof WebGLGlobeDataSource.prototype
     * @type {EntityCluster}
     */
    clustering: {
        get: function () {
            return this._entityCluster;
        },
        set: function (value) {
            if (!Cesium.defined(value)) {
                throw new Cesium.DeveloperError("value must be defined.");
            }
            this._entityCluster = value;
        },
    },
});

/**
 * Loads the provided data, replacing any existing data.
 * @param {Array} data The object to be processed.
 */
WebGLGlobeDataSource.prototype.load = function (data) {
    //>>includeStart('debug', pragmas.debug);
    if (!Cesium.defined(data)) {
        throw new Cesium.DeveloperError("data is required.");
    }
    //>>includeEnd('debug');

    //Clear out any data that might already exist.
    this._setLoading(true);
    this._seriesNames.length = 0;
    // this._seriesToDisplay = undefined;

    var heightScale = this.heightScale;
    var entities = this._entityCollection;

    //It's a good idea to suspend events when making changes to a
    //large amount of entities.  This will cause events to be batched up
    //into the minimal amount of function calls and all take place at the
    //end of processing (when resumeEvents is called).
    entities.suspendEvents();
    entities.removeAll();

    //WebGL Globe JSON is an array of series, where each series itself is an
    //array of two items, the first containing the series name and the second
    //being an array of repeating latitude, longitude, height values.
    //
    //Here's a more visual example.
    //[["series1",[latitude, longitude, height, ... ]
    // ["series2",[latitude, longitude, height, ... ]]

    // Loop over each series
    for (var x = 0; x < data.length; x++) {

        var series = data[x];
        var seriesName = series[0];
        var coordinates = series[1];

        //Add the name of the series to our list of possible values.
        this._seriesNames.push(seriesName);

        //Make the first series the visible one by default
        var show = (this._show && seriesName === this.seriesToDisplay);

        // 首先遍历一遍数据，找到最大值,用来归一化
        let maxZValue = -1;
        for (let i = 2; i < coordinates.length; i += 3) {
            if (coordinates[i] > maxZValue) {
                maxZValue = coordinates[i]
            }
        }

        //Now loop over each coordinate in the series and create
        // our entities from the data.
        for (var i = 0; i < coordinates.length; i += 3) {
            var latitude = coordinates[i];
            var longitude = coordinates[i + 1];
            var height = coordinates[i + 2];

            //Ignore lines of zero height.
            if (height <= 0) {
                continue;
            }
            let color = defaultShader(height)
            if (typeof this.shaders === "object") {
                color = Cesium.Color.fromCssColorString(this.shaders[seriesName](height));
            }

            var surfacePosition = Cesium.Cartesian3.fromDegrees(
                longitude,
                latitude,
                0
            );
            var heightPosition = Cesium.Cartesian3.fromDegrees(
                longitude,
                latitude,
                height * heightScale / maxZValue + 40000
            );


            var entity = new Cesium.Entity({
                position: surfacePosition,
                cylinder: {
                    length: height * heightScale / maxZValue,
                    topRadius: 40000.0,
                    bottomRadius: 40000.0,
                    material: new Cesium.ColorMaterialProperty(color),
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                },

                show: show,
                seriesName: seriesName
            });

            var label = new Cesium.Entity({
                seriesName: seriesName,
                position: heightPosition,
                show: show,
                label: {
                    // This callback updates the length to print each frame.
                    // heightReference:  Cesium.HeightReference.CLAMP_TO_GROUND,
                    text: height + "",
                    font: "16px sans-serif",
                    pixelOffset: new Cesium.Cartesian2(0.0, 0.0),
                    // fillColor:Cesium.Color.BLACK,
                    // scaleByDistance: new Cesium.NearFarScalar(1000000, 1, 10000000, 0.2)
                },
            });

            entities.add(label);
            //Add the entity to the collection.
            entities.add(entity);
        }
    }


    //Once all data is processed, call resumeEvents and raise the changed event.
    entities.resumeEvents();
    this._changed.raiseEvent(this);
    this._setLoading(false);
};

WebGLGlobeDataSource.prototype._setLoading = function (isLoading) {
    if (this._isLoading !== isLoading) {
        this._isLoading = isLoading;
        this._loading.raiseEvent(this, isLoading);
    }
};

export default WebGLGlobeDataSource;
