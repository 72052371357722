/*
 * Created at 2020/8/15 下午10:17 by lhq
 * 项目监测窗口目录树结构
 */

import React, {Component} from "react";
import {Tree, Input, Col, Row, Tooltip, Modal} from "antd";
import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {DownOutlined} from '@ant-design/icons'
import './LayerCatelog.css'
import {
    layerActions,
    getLayerMap,
    getExpandedNodes,
    getCheckedNodes,
    getAutoExpandParent,
    getCheckedLayers,
    getLayerSearchText, getMonitorTreeData
} from '../../redux/modules/layers'

import {WorldMapIcon, DetailInfoIcon} from "../utils";

const {TreeNode} = Tree;
const {Search} = Input;

const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};

const dataList = [];

const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        const {key, alias} = node;
        dataList.push({key, title: alias || key});
        if (node.children) {
            generateList(node.children);
        }
    }
};

class MonitorCatalog extends Component {

    componentDidMount() {

    }

    onExpandNode = expandedKeys => {
        this.props.expandNodes(expandedKeys)
        this.props.toggleAutoExpandParent(false)
    };

    onSearchTextChange = e => {
        if (dataList.length === 0) {
            generateList(this.props.layerTreeData)
        }
        const {value} = e.target

        const expandedKeys = dataList.map(item => {
            if (value !== '' && item.title.indexOf(value) > -1) {

                return getParentKey(item.key, this.props.layerTreeData);
            }
            return null;
        }).filter((item, i, self) => item && self.indexOf(item) === i);

        this.props.expandNodes(expandedKeys);
        this.props.updateLayerSearchText(value)
        this.props.toggleAutoExpandParent(true);
    };


    render() {

        const treeBuilder = (data, layerSearchText) => {

            return data.map(item => {
                const alias = item.alias || item.key
                const index = alias.indexOf(layerSearchText);
                const beforeStr = alias.substr(0, index);
                const afterStr = alias.substr(index + layerSearchText.length);
                const title = <span>{alias}</span>

                if (item.children) {
                    return (
                        <TreeNode key={item.key} title={title} checkable={false} onClick={(e) => {
                        }}>
                            {treeBuilder(item.children, layerSearchText)}
                        </TreeNode>
                    );
                }
                return <TreeNode key={item.key}
                                 selectable={false}
                                 title={item.extraInfo ? <Tooltip color={"transparent"} placement="right" title={<a
                                     onClick={e => {
                                         e.preventDefault();
                                         e.stopPropagation();
                                         this.props.viewLayerInfo(item.key)
                                     }}>详情</a>}>{title}</Tooltip> : title}/>;
            });
        }


        return (<>
            {/*<Row justify={'center'}>*/}
            {/*    <Col span={22}>*/}
            {/*        <Search style={{marginBottom: 8, width: '95%'}} placeholder="图层检索"*/}
            {/*                onChange={this.onSearchTextChange}/>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            <Row justify={'end'}>
                <Col span={23}>
                    <Tree
                        draggable={false}
                        // height={700}
                        checkable
                        showLine
                        showIcon={true}
                        selectable={true}
                        onSelect={this.onSelectNode}
                        switcherIcon={<DownOutlined/>}
                        onExpand={this.onExpandNode}
                        checkedKeys={this.props.checkedNodes}
                        onCheck={this.onCheckNode}
                        expandedKeys={this.props.expandedNodes}
                        autoExpandParent={this.props.autoExpandParent}
                    >
                        {treeBuilder(this.props.monitorTreeData, this.props.layerSearchText)}
                    </Tree>
                </Col>
            </Row>

        </>)
    }


        onSelectNode = (selectedKeys, e) => {

        let key = e.node.key
        if (e.selected) {
            this.props.expandNodes([...this.props.expandedNodes, key])
        } else {
            let expandedNodes = [...this.props.expandedNodes]

            expandedNodes.splice(expandedNodes.findIndex(item => item === key), 1);

            this.props.expandNodes(expandedNodes)

        }
    }

    onCheckNode = (checkedKeys, e) => {
        const combineCheckedKeys = new Set(this.props.checkedNodes.concat(...checkedKeys))

        if (!e.checked) {
            combineCheckedKeys.delete(e.node.key)
        }

        this.props.toggleCheckedNodes(Array.from(combineCheckedKeys), e.node.key, e.checked)

    };


}

const mapStateToProps = (state, props) => {
    return {
        checkedLayers: getCheckedLayers(state),
        layerMap: getLayerMap(state),
        expandedNodes: getExpandedNodes(state),
        checkedNodes: getCheckedNodes(state),
        autoExpandParent: getAutoExpandParent(state),
        layerSearchText: getLayerSearchText(state),
        monitorTreeData: getMonitorTreeData(state),

    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(layerActions, dispatch)
    };
};


MonitorCatalog.contextTypes = {
    zoomTo: PropTypes.func
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(MonitorCatalog));

