/*
 * Created at 2022/1/18 下午2:48 by lhq
 */

/*
 * Create 2020/4/27 下午3:49 by lhq
 */

import React, {Component} from "react";
import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Carousel, Col, Form, Progress, Radio, Rate, Row} from 'antd';
import {Radar} from 'react-chartjs-2';
import {LoadingOutlined,} from '@ant-design/icons';
import {
    appActions,
    getPopupCartesian3,
    getPopupChartData1,
    getPopupChartData2,
    getPopupCountry,
    getPopupData,
    getPopupGradeData,
    getPopupLoading,
    getPopupOpen,
    getPopupType,
    getPopupXY,
} from "../../redux/modules/app";
import './ResponsivePopup.css'


const options = {

    legend: {
        display: false,
        color: "#fff",
        fontColor: '#fff'
    },

    scale: {
        angleLines: {
            display: true,
            color: "#fff",
            lineWidth: 0.6
        },
        gridLines: {
            color: '#bbb',
            lineWidth: 0.5
        },
        pointLabels: {
            fontColor: '#fff',
            fontSize: 10,
            fontStyle: 'bold'
        },
        ticks: {
            showLabelBackdrop: false,
            fontColor: '#fff',
            suggestedMin: 0,
            suggestedMax: 5.0,
            max: 5.0,
            min: 0,
            stepSize: 1,
            display: true
        }
    }
};


class ResponsivePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            activeCarousel: 1
        }

        this.viewer = props.viewer;

    }

    calcProgressColor = values => {

        let total = values.reduce((p, c) => p + c)
        if (total < 20) {
            return "#FF0000"
        } else if (total >= 28 && values.filter(v => v < 3).length === 0) {
            return "#33FF00"
        } else {
            return "#FFFF00"
        }
    }

    render() {
        let {pos, off} = this.props;

        const containerWidth = 300;
        const containerHeight = 400;


        let x = this.props.popupXY[0];
        let y = this.props.popupXY[1];

        let left = x;
        let top = y;

        let tooltipContainerPos = {
            top: y,
            left: x,
            marginTop: 10
        }

        let tooltipPos = {
            marginTop: "10px"
        }

        // eslint-disable-next-line default-case
        switch (pos) {
            case "Top":
                // 上
                tooltipContainerPos = {
                    top: top - 20,
                    left: x,
                    marginTop: "11px"
                }
                tooltipPos = {
                    marginTop: "-10px"
                }
                left = x - off
                top = top - 400 - 10;
                break;
            case "Bottom":
                // 下
                left = x - off
                tooltipContainerPos = {
                    top: top,
                    left: x,
                    marginTop: "-9px"
                }
                tooltipPos = {
                    marginTop: "11px"
                }
                top = top + 10;

                break;

            // case "Right":
            //     // 右
            //     top = top - off;
            //     break;
            // case "Left":
            //     // 左
            //     left = x - 300;
            //     top = top - off;
            //     break;
        }


        return (
            <div>
                <div
                    className={(this.props.popupOpen && this.props.popupType === "chart") ? "popup-show" : "popup-hide"}
                    ref={e => {
                        this.popup = e;
                    }} style={{left: left, top: top}}>
            <span className={"popup-closer"} id={"popup-closer"} onClick={_e => {
                this.props.togglePopup(false)
            }}/>
                    <div id={"popup-content"}>
                        <Row justify={"start"}>
                            <Col span={24}>
                                <Form layout={"horizontal"}>
                                    <Form.Item label={this.props.loading ? <LoadingOutlined/> : this.props.popupCountry}
                                               size={"small"} labelCol={{span: 8, offset: 1}} style={{marginBottom: 0}}>
                                        <Radio.Group size={"small"} buttonStyle={"solid"} defaultValue={0}
                                                     onChange={(e) => {
                                                         this.carousel.goTo(e.target.value)
                                                     }}>

                                            <Radio.Button value={0} defaultChecked={true}>风险图</Radio.Button>
                                            <Radio.Button value={1}>评分</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>

                        <Carousel dotPosition={"top"} dots={false} effect={"fade"} ref={e => {
                            this.carousel = e;
                        }}>
                            {/*<div className={"radar-container2"}>*/}
                            {/*    <Radar height={310} width={270} data={this.props.popupChartData2} options={{*/}
                            {/*        ...options, scale: {*/}
                            {/*            ...options.scale,*/}
                            {/*            pointLabels: {*/}
                            {/*                fontColor: '#fff',*/}
                            {/*                fontSize: 12,*/}
                            {/*                fontStyle: 'bold'*/}
                            {/*            }*/}
                            {/*        }*/}
                            {/*    }}/>*/}
                            {/*    <div>*/}
                            {/*        <Col span={18} offset={3}>*/}
                            {/*            <Progress*/}
                            {/*                percent={Object.values(this.props.popupGradeData).reduce((p, c) => p + c) * 100 / 40}*/}
                            {/*                strokeColor={this.calcProgressColor(Object.values(this.props.popupGradeData))}*/}
                            {/*                format={(total) => Math.round(total) + "分"}/>*/}
                            {/*        </Col>*/}

                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className={"radar-container1"}>
                                <Radar height={310} width={270} data={this.props.popupChartData1} options={options}/>
                                <div>
                                    <Col span={18} offset={3}>
                                        <Progress
                                            percent={Object.values(this.props.popupGradeData).reduce((p, c) => p + c) * 100 / (5 * (Object.values(this.props.popupGradeData).length))}
                                            strokeColor={this.calcProgressColor(Object.values(this.props.popupGradeData))}
                                            format={(total) => Math.round(total) + "分"}/>
                                    </Col>

                                </div>
                            </div>


                            <div>
                                <div style={{
                                    "height": "340px",
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "justifyContent": "space-between"
                                }}>
                                    {Object.keys(this.props.popupGradeData).map(field => (
                                        <span className={"index-rate"} key={field}>
                            <span className="ant-rate-text">{field}</span>
                            <Rate allowHalf allowClear={false} style={{fontSize: '16px'}}
                                  value={this.props.popupGradeData[field]} disabled/>
                            </span>
                                    ))}
                                </div>


                                {/*<span className={"index-rate"} style={{marginTop: '20px'}}>*/}
                                {/*<span className="ant-rate-text">资环承载力：</span>*/}
                                {/*<Rate allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["资源承载力"]} disabled/>*/}
                                {/*</span>*/}

                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">环境安全度：</span>*/}
                                {/*<Rate allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["环境安全度"]} disabled/>*/}
                                {/*</span>*/}

                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">灾害安全度：</span>*/}
                                {/*<Rate allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["灾害安全度"]} disabled/>*/}
                                {/*</span>*/}

                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">碳排放空间：</span>*/}
                                {/*<Rate style={{fontSize: '18px'}} value={this.props.popupGradeData["碳排放空间"]}*/}
                                {/*      disabled/>*/}
                                {/*</span>*/}

                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">对外依赖度：</span>*/}
                                {/*<Rate allowHalf allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["对外依赖度"]} disabled/>*/}
                                {/*</span>*/}

                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">中国投资环境：</span>*/}
                                {/*<Rate allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["中国投资环境"]} disabled/>*/}
                                {/*</span>*/}
                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">发展水平：</span>*/}
                                {/*<Rate allowHalf allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["发展水平"]} disabled/>*/}
                                {/*</span>*/}
                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">社会稳定度：</span>*/}
                                {/*<Rate allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["社会稳定度"]} disabled/>*/}
                                {/*</span>*/}

                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">治理稳定度：</span>*/}
                                {/*<Rate allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["治理稳定度"]} disabled/>*/}
                                {/*</span>*/}

                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">地缘安全度：</span>*/}
                                {/*<Rate allowHalf allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["地缘安全度"]} disabled/>*/}
                                {/*</span>*/}


                                {/*<span className={"index-rate"}>*/}
                                {/*<span className="ant-rate-text">主权信用：</span>*/}
                                {/*<Rate allowHalf allowClear={false} style={{fontSize: '18px'}}*/}
                                {/*      value={this.props.popupGradeData["主权信用"]} disabled/>*/}
                                {/*</span>*/}


                                <Col span={18} offset={3}>
                                    <Progress
                                        percent={Object.values(this.props.popupGradeData).reduce((p, c) => p + c) * 100 / 50}
                                        strokeColor={this.calcProgressColor(Object.values(this.props.popupGradeData))}
                                        format={(total) => Math.round(total) + "分"}/>
                                </Col>
                            </div>

                        </Carousel>
                    </div>
                </div>
                <div className="cesium-popup-tip-container" style={{
                    ...tooltipContainerPos,
                    display: (this.props.popupOpen && this.props.popupType === "chart") ? "block" : "none"
                }}>
                    <div className="cesium-popup-tip" style={{...tooltipPos}}/>
                </div>
            </div>)
    }

}

const mapStateToProps = (state, _props) => {
    return {
        loading: getPopupLoading(state),
        popupType: getPopupType(state),
        popupOpen: getPopupOpen(state),
        popupXY: getPopupXY(state),
        popupCartesian3: getPopupCartesian3(state),
        popupData: getPopupData(state),
        popupCountry: getPopupCountry(state),
        popupGradeData: getPopupGradeData(state),
        popupChartData1: getPopupChartData1(state),
        popupChartData2: getPopupChartData2(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(appActions, dispatch)
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(ResponsivePopup));

