import {withTask} from 'react-palm/tasks'
import {createAction, handleActions} from 'redux-actions'
import {fromPromise} from 'react-palm/tasks/core'
import {get, SPRING_HOST} from '../../components/utils'

// const zeroPopupGradeData = {
//     '社会复杂度': 0,
//     "社会稳定度": 0,
//     "债务风险": 0,
//     "治理能力": 0,
//     "冲突安全形势": 0,
//     "主权信用": 0,
//     "地缘安全度": 0,
//     "投资环境": 0
// }

const zeroPopupGradeData = {
    "灾害安全度": 0,
    "资源承载力": 0,
    "环境安全度": 0,
    "中国投资环境": 0,
    "社会稳定度": 0,
    "地缘安全度": 0,
    "对外依赖度": 0,
    "发展水平": 0,
    "碳排放空间": 0,
    "治理稳定度": 0,
    "主权信用": 0,
}

const rateScoresFields = {
    "zqxy": "主权信用",
    "ctsj": "冲突安全形势",
    "zwfx": "债务风险",
    "shwdd": "社会稳定度",
    "zlnl": "治理能力",
    "dygx": "地缘安全度",
    "ssfzd": "社会复杂度",
    "tzhj": "投资环境",
    "fzsp": "发展水平",
    "tpfkj": "碳排放空间",
    "zhaqd": "灾害安全度",
    "zhczl": "资环承载力",
    "hjaqd": "环境安全度"
}

const zeroPopupChartData1 = {
    labels: Object.keys(rateScoresFields),
    datasets: [{
        label: '指标雷达图',
        backgroundColor: 'rgba(255,215,0,0.3)',
        borderColor: 'rgba(255,215,0,1)',
        pointBackgroundColor: 'rgba(255,215,0,1)',
        pointBorderColor: '#FFA500',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,215,0,1)',
        borderWidth: 2,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0,0,0]
    }]
}


const zeroPopupChartData2 = {
    labels: ['绿色', '安全', '发展'],
    datasets: [{
        label: '绿色安全发展',
        backgroundColor: 'rgba(33,215,0,0.3)',
        borderColor: 'rgba(33,215,0,1)',
        pointBackgroundColor: 'rgba(33,255,0,1)',
        pointBorderColor: '#33ca33',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(33,255,0,1)',
        borderWidth: 2,
        data: [0, 0, 0]
    }]
}

const hasDetailCountries = ["巴基斯坦", "白俄罗斯", "波兰", "俄罗斯", "格鲁吉亚", "哈萨克斯坦", "吉尔吉斯斯坦", "柬埔寨", "肯尼亚", "老挝", "马来西亚", "蒙古", "孟加拉国", "缅甸", "塔吉克斯坦", "泰国", "土耳其", "土库曼斯坦", "乌兹别克斯坦", "希腊", "印度尼西亚", "越南"]

const initialState = {
    loading: false,
    mapProvider: 'cesium',
    popupCountry: "非古丝路沿线国",
    popupGradeData: zeroPopupGradeData,
    popupChartData1: zeroPopupChartData1,
    popupChartData2: zeroPopupChartData2,
    popupXY: [0, 0],
    popupCartesian3: undefined,
    allRadarData: [], // 64+1 雷达图的数据
    allRadarDrawerOpen: false,
    siderOpen: true,
    siderWidth: 450,
    popupOpen: false,
    catalogPanelHeight: 360,
    popupType: "chart", // chart 综合评价, doc 国别情况
    hasCountryInfo: false,
    activeDownloadedLayer: undefined,
}

const fetchPopupDataTask = fromPromise(({
                                            lng,
                                            lat
                                        }) => get(`${SPRING_HOST}/popup/getRateScores.do?lng=${lng}&lat=${lat}`), 'fetchPopupDataTask')
const fetchAllRadarDataTask = fromPromise(() => get(`${SPRING_HOST}/popup/getAllRadarData.do`), 'fetchAllRadarDataTask')
const toggleAllRadarDrawer = createAction('toggle_all_radar_drawer')
const setPopupData = createAction('set_popup_data');
const setPopupXY = createAction("set_popup_xy");
const setPopupCartesian3 = createAction('set_popup_cartesian3');
const fetchPopupData = createAction('fetch_popup_data', (lng, lat) => ({lng, lat}));
const fetchAllRadarData = createAction('fetch_all_radar_data');
const setAllRadarData = createAction('set_all_radar_data');
const toggleSider = createAction("toggle_sider");
const togglePopup = createAction("toggle_popup");
const togglePopupType = createAction("toggle_popup_type")
const toggleMapProvider = createAction("toggle_map_provider")
const updateAppFields = createAction('updateAppFields');

const reducer = handleActions(
    {
        [updateAppFields]: (state, {payload}) => {
            return {...state, ...payload}
        },
        [setPopupXY]: (state, {payload}) => {
            return {
                ...state,
                popupXY: payload
            };
        },
        [toggleAllRadarDrawer]: (state) => {
            return {...state, allRadarDrawerOpen: !state.allRadarDrawerOpen}
        },
        [setPopupCartesian3]: (state, {payload}) => {
            return {
                ...state,
                popupCartesian3: payload
            };
        },
        // 2022年04月24日 注8个指标版本
        [setPopupData]: (state, {payload}) => {

            const {error, name} = payload

            if (error) {
                return {...state, loading: false}
            }


            // let labels1 = Object.values(rateScoresFields)

            let keys =  Object.keys(payload);

            let labels1 = keys.map(k=>rateScoresFields[k]).filter(l=>l!==undefined);

            let data1 = Object.keys(rateScoresFields).map(field => payload[field])


            const hasCountryInfo = hasDetailCountries.find((x) => x === name);

            return {
                ...state,
                popupCountry: name,
                popupGradeData: labels1.reduce((result, item, index, array) => {

                    result[item] = data1[index];

                    return result;
                }, {}),
                loading: false,
                popupChartData1: {
                    ...state.popupChartData1,
                    labels: labels1.map((label, index) => {
                        if (data1[index] !== 0) {
                            return label
                        } else {
                            return label + '(无数据)';
                        }
                    }),
                    datasets: [{
                        ...state.popupChartData1.datasets[0], data: [...data1],
                        pointBackgroundColor: data1.map(value => value !== 0 ? 'rgba(255,215,0,1)' : '#fff'),
                        pointBorderColor: data1.map(value => value !== 0 ? '#FFA500' : '#fff')
                    },
                    ]
                },
                hasCountryInfo,
                popupType: hasCountryInfo ? state.popupType : "chart",
                popupOpen: true,
            }
        },

        // [setPopupData]: (state, {payload}) => {
        //
        //     const {error, country, indexMap1} = payload
        //
        //     if (error) {
        //         return {...state, loading: false}
        //     }
        //
        //
        //     let labels1 = Object.keys(indexMap1)
        //     let data1 = labels1.map(field => indexMap1[field])
        //
        //
        //     const hasCountryInfo = hasDetailCountries.find((x) => x === country);
        //
        //     return {
        //         ...state,
        //         popupCountry: country,
        //         popupGradeData: labels1.reduce((result, item, index, array) => {
        //             result[item] = data1[index];
        //             return result;
        //         }, {}),
        //         loading: false,
        //         popupChartData1: {
        //             ...state.popupChartData1,
        //             labels: labels1.map((label, index) => {
        //                 if (data1[index] !== 0) {
        //                     return label
        //                 } else {
        //                     return label + '(无数据)';
        //                 }
        //             }),
        //             datasets: [{
        //                 ...state.popupChartData1.datasets[0], data: [...data1],
        //                 pointBackgroundColor: data1.map(value => value !== 0 ? 'rgba(255,215,0,1)' : '#fff'),
        //                 pointBorderColor: data1.map(value => value !== 0 ? '#FFA500' : '#fff')
        //             },
        //             ]
        //         },
        //         // popupChartData2: {
        //         //     ...state.popupChartData2,
        //         //     labels: [...labels2],
        //         //     datasets: [{...state.popupChartData2.datasets[0], data: [...data2]}]
        //         // },
        //         hasCountryInfo,
        //         popupType: hasCountryInfo ? state.popupType : "chart",
        //         popupOpen: true,
        //     }
        // },
        [fetchPopupData]: (state, {payload}) => {

            return withTask({...state, loading: true}, fetchPopupDataTask(payload).map(setPopupData))
        },

        [setAllRadarData]: (state, {payload}) => {
            let allRadarData = payload.map(item => {
                let {indexMap, country} = item
                let labels = Object.keys(indexMap)
                let data = Object.values(indexMap)
                return {
                    ...state.popupChartData,
                    labels: [...labels],
                    datasets: [{...state.popupChartData.datasets[0], label: country, data: [...data]}]
                }

            })

            return {...state, allRadarData: [...allRadarData]}
        },

        [fetchAllRadarData]: (state) => {
            return withTask({...state}, fetchAllRadarDataTask().map(setAllRadarData))
        },

        [toggleSider]: (state, {payload}) => {
            if (payload) {
                return {...state, siderOpen: payload, siderWidth: 450}
            }
            return {...state, siderOpen: payload, siderWidth: 0}
        },
        [togglePopup]: (state, {payload}) => {
            return {...state, popupOpen: payload}
        },

        [togglePopupType]: (state, {payload}) => {
            return {...state, popupType: payload}
        },
        [toggleMapProvider]: (state, {payload}) => {
            return {...state, mapProvider: payload}
        }

    },
    initialState
);


export default reducer;

export const appActions = {
    setPopupXY,
    setPopupCartesian3,
    fetchPopupData,
    toggleSider,
    togglePopup,
    fetchAllRadarData,
    toggleAllRadarDrawer,
    togglePopupType,
    toggleMapProvider,
    updateAppFields

}

// selectors
export const getPopupLoading = state => state.app.loading
export const getPopupCountry = state => state.app.popupCountry
export const getPopupGradeData = state => state.app.popupGradeData
export const getPopupChartData1 = state => state.app.popupChartData1
export const getPopupChartData2 = state => state.app.popupChartData2
export const getPopupXY = state => state.app.popupXY
export const getPopupCartesian3 = state => state.app.popupCartesian3
export const getPopupData = state => state.app.popupData
export const getPopupOpen = state => state.app.popupOpen
export const getPopupType = state => state.app.popupType
export const getHasCountryInfo = state => state.app.hasCountryInfo
export const getMapProvider = state => state.app.mapProvider


export const getDrawerOpen = state => {
    return state.app.drawerOpen
}
export const getSiderOpen = state => {
    return state.app.siderOpen
}
export const getSiderWidth = state => {
    return state.app.siderWidth
}
