/*
 * Created at 2020/6/4 上午8:27 by lhq
 */

import {withTask} from 'react-palm/tasks'

import {createAction, handleActions} from 'redux-actions'

import {fromPromise} from 'react-palm/tasks/core'

import {get, post, SPRING_HOST} from '../../components/utils'

import {message} from "antd";
import moment from "moment";

const emptyAuthInfo = {
    "access_token": "",
    "token_type": "",
    "refresh_token": "",
    "expires_in": 0,
    "scope": "all",
    "authorities": [],
    "username": "User_001",
    "name": "游客",
    "expires_time": 0,
    "authed": false
}


const authInfo = (() => {
    let authInfo = localStorage.getItem("ydyl.loginStorage");
    if (!authInfo) {
        return emptyAuthInfo
    } else {
        authInfo = JSON.parse(authInfo)
        return authInfo;
    }

})()

const initState = {
    name: authInfo.name,
    remember: false,
    authed: true,
    accessToken: authInfo.access_token,
    shouldRedirect: false,
    needChangePassword: false,
    remindTomorrow: false,
    redirect: "/"
}


const doLogin = createAction("do_login")

const handleLogin = createAction("handle_login")

const toggleAuthed = createAction("toggle_authed")

const toggleRemindTomorrow = createAction("toggle_remind_tomorrow")

const getAuthState = createAction("get_auth_state")

const checkNeedChangePassword = createAction("checkNeedChangePassword")
const setNeedChangePassword = createAction("setNeedChangePassword")

const doLogout = createAction("do_logout")

const setRedirect = createAction("set_redirect")


const getAuthInfoTask = fromPromise(() => {

        let authInfo = localStorage.getItem("ydyl.loginStorage")
        if (authInfo !== null) {
            authInfo = JSON.parse(authInfo)
            let expiresTime = authInfo.expires_time
            if (new Date().getTime() > expiresTime) {
                return Promise.resolve(emptyAuthInfo)
            } else {
                let remindTomorrow = false;
                let lastPostpone = localStorage.getItem("ydyl.dont_remind_me");
                // console.log(moment().diff(moment(lastPostpone), "hour"))
                if (lastPostpone != null && moment().diff(moment(lastPostpone), "hour") < 24) {
                    remindTomorrow = true;
                }
                return get(`${SPRING_HOST}/user/getProfile?t=${+new Date()}`, {"Authorization": "Bearer " + authInfo.access_token}).then(
                    res => {
                        if (res.data) {
                            return Promise.resolve({
                                ...authInfo,
                                name: res.data.name,
                                remindTomorrow,
                                lastChangePasswordTime: res.data.updateTime
                            })
                        } else {
                            return Promise.resolve(emptyAuthInfo)
                        }
                    }
                )
            }
        }
        return Promise.resolve(emptyAuthInfo)
    }
    , "getAuthInfoTask")

const loginTask = fromPromise((value) => post(`${SPRING_HOST}/user/login`, value), "loginTask")

const reducer = handleActions({
    // [doLogin]: (state, {payload}) => {
    //     console.log(payload)
    //     const {username, password, remember} = payload;
    //
    //     if (username === "slhj" && password === "slhj1234") {
    //         if (remember) {
    //             localStorage.setItem("ydyl.loginStorage", JSON.stringify({...payload, authed: true}))
    //         } else {
    //             localStorage.removeItem("ydyl.loginStorage")
    //         }
    //
    //         return {...state, shouldRedirect: true, authed: true, username: payload.username}
    //     }
    //
    //     return {...state, shouldRedirect: false, authed: false}
    //
    // },

    [doLogout]: (state, action) => {
        return {...state, authed: false, shouldRedirect: false}
    },
    [setRedirect]: (state, {payload}) => {

        return {...state, redirect: payload}

    },

    [handleLogin]: (state, {payload}) => {

        if (payload.data != null && payload.msg === "成功") {
            if (state.remember) {
                localStorage.setItem("ydyl.loginStorage", JSON.stringify({
                    ...payload.data,
                    authed: true,
                    name: payload.data.name
                }))
            } else {

                localStorage.removeItem("ydyl.loginStorage")
            }

            // if (payload.data.needChangePassword) {
            //     // 需要修改密码
            //     message.info("您已经超过90天未修改密码，为了账号安全，请修改密码！")
            //     return {
            //         ...state,
            //         authed: true,
            //         shouldRedirect: true,
            //         redirect: "/user/profile#changePassword",
            //         accessToken: payload.access_token,
            //     }
            // } else {
            // }
            return {...state, authed: true, shouldRedirect: true, accessToken: payload.access_token,}


        } else {
            localStorage.removeItem("ydyl.loginStorage")
            message.error(payload.msg || "登录失败")
            return {...state, authed: false, shouldRedirect: false, accessToken: ''}
        }
    },

    [toggleAuthed]: (state, {payload}) => {
        return {
            ...state,
            name: payload.name,
            authed: payload.authed,
            accessToken: payload.access_token,
            shouldRedirect: payload.authed,
            redirect: "/"
        }
    },

    [toggleRemindTomorrow]: (state, {payload}) => {
        localStorage.setItem("ydyl.dont_remind_me", moment().format());
        return {
            ...state,
            remindTomorrow: true,
        }
    },

    [setNeedChangePassword]: (state, {payload}) => {
        let needChangePassword = !payload.lastChangePasswordTime || moment().diff(moment(payload.lastChangePasswordTime), "days") >= 90
        return {
            ...state,
            name: payload.name,
            authed: payload.authed,
            accessToken: payload.access_token,
            shouldRedirect: false,
            remindTomorrow: payload.remindTomorrow,
            needChangePassword,
            redirect: "/"
        }
    },

    [getAuthState]: (state, {payload}) => {
        return withTask(state, getAuthInfoTask().map(toggleAuthed))
    },

    [checkNeedChangePassword]: (state, {payload}) => {

        return withTask(state, getAuthInfoTask().map(setNeedChangePassword))
    },


    [doLogin]: (state, {payload}) => {

        const {remember} = payload;

        return withTask({...state, remember}, loginTask(payload).map(handleLogin))
    }


}, initState)

export default reducer

export const loginActions = {
    doLogin,
    getAuthState,
    checkNeedChangePassword,
    toggleRemindTomorrow,
    doLogout,
    setRedirect
}
