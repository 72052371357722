import {
    ProviderViewModel,
    CesiumTerrainProvider,
    EllipsoidTerrainProvider,
    IonResource,
    Ion
} from "cesium";

import ellipsoid from '../assets/images/ellipsoid.png'
import cesiumTerrain from '../assets/images/cesiumTerrain.png'

Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyODkzNTAzYy00ZWMzLTQ4ZDUtYjI4MS1hMzA4YWIxYWMwZWIiLCJpZCI6MTIwMjcsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1OTI4MTE4MTB9.-ZNGt6jC85O45aRkAi_8en6r-IGt9y4BMsuYmU9msKc";

const terrainLayers = [

    new ProviderViewModel({
        name: "标准椭球面",
        tooltip: "标准椭球面",
        iconUrl: ellipsoid,
        creationFunction: () => new EllipsoidTerrainProvider()

    }),

    new ProviderViewModel({
        name: "全球地形",
        tooltip: "全球地形",
        iconUrl: cesiumTerrain,
        creationFunction: () => new CesiumTerrainProvider({
            url: IonResource.fromAssetId(1),
            requestVertexNormals: true
        })

    }),


]

export default terrainLayers
