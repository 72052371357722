/*
 * Created at 2020/7/12 下午2:34 by lhq
 */
import React, {Component} from "react";
import {Row, Col, Collapse, Switch, Empty, Card, List, Button, Modal, Tree} from 'antd';
import LayerCatelog from "./LayerCatelog";
import ActiveLayerListItem from "../widget/ActiveLayerListItem";
import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Radar} from 'react-chartjs-2';

import {DownOutlined} from '@ant-design/icons'
import SplitPane, {Pane} from "react-split-pane";
import {
    appActions
} from '../../redux/modules/app'

import {layerActions, getCheckedLayers} from "../../redux/modules/layers";
import "./ProjectMonitorTab.css"


import ImportantCatalog from "./ImportantCatalog";

import {DocIcon, ChartIcon, DeleteIcon, MoveUpIcon, SPRING_HOST} from "../utils";

import {table13ShareLayerSet} from "../../config/layerConfig";

const {TreeNode} = Tree;
const {Panel} = Collapse;

// const options = {
//
//     title: {
//         display: true,
//         position: 'top',
//         fontColor: "#fff",
//         padding: 2,
//         fontSize: 16,
//     },
//     legend: {
//         display: false,
//         position: 'bottom',
//         color: "#fff",
//         fontColor: '#fff'
//     },
//
//     scale: {
//         angleLines: {
//             display: true,
//             color: "#fff",
//             lineWidth: 0.6
//         },
//         gridLines: {
//             color: '#bbb',
//             lineWidth: 0.5
//         },
//         pointLabels: {
//             fontColor: '#fff',
//             fontSize: 10,
//             fontStyle: 'bold'
//         },
//         ticks: {
//             showLabelBackdrop: false,
//             fontColor: '#fff',
//             suggestedMin: 0,
//             suggestedMax: 5.0,
//             max: 5.0,
//             min: 0,
//             stepSize: 1,
//             display: true
//         }
//     }
// };


class RiskEvaluateTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false
        }
    }


    componentDidMount() {
        // this.props.fetchAllRadarData()

    }

    viewLayerInfo = (layer) => {
        this.setState({
            modalVisible: true,
            layerName: layer
        })

    }

    render() {
        return (
            <>
                <Row gutter={[0, 24]} justify={"center"}>
                    <Col span={24}>
                        <ImportantCatalog key={"0"} viewLayerInfo={this.viewLayerInfo}/>
                    </Col>
                </Row>
                <Modal
                    className={"layer-info-modal"}
                    title={<span><DocIcon/>{this.state.layerName}</span>}
                    closable={true}
                    mask={false}
                    maskClosable={true}
                    getContainer={document.getElementById("contentContainer")}
                    width={document.body.clientWidth - 750}
                    bodyStyle={{backgroundColor: "#fff"}}
                    onCancel={e => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    footer={false}
                    style={{marginLeft: '600px'}}
                >
                    <iframe
                        style={{width: '100%', overflow: 'auto'}}
                        src={`${SPRING_HOST}/poster/${this.state.layerName}.html`}
                        width="100%"
                        height={"640px"}
                        frameBorder="0"
                    />
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        allRadarData: ((state) => state.app.allRadarData)(state),
        checkedLayers: getCheckedLayers(state),
        allRadarDrawerOpen: ((state) => state.app.allRadarDrawerOpen)(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(appActions, dispatch),
        ...bindActionCreators(layerActions, dispatch),
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(RiskEvaluateTab));
