import React from "react";
import {hot} from "react-hot-loader/root";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {layerActions, getTopVisibleLayer} from "../../../redux/modules/layers";
import WrappedTimeline from "cesium/Source/Widgets/Timeline/Timeline";
import JulianDate from 'cesium/Source/Core/JulianDate'
import ClockRange from "cesium/Source/Core/ClockRange";
import {formatDateTime, formatDate, formatTime, formatMonth} from "./DateFormats";
import dateFormat from "dateformat";

import "./cesium-timeline.css"


class CesiumTimeline extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {

        // 比较 checkedLayer 的变化
        // 用于设置 viewer clock 为当前可见的最上层图层的时钟
        let prevTopVisibleLayer = prevProps.topVisibleLayer;
        let curTopVisibleLayer = this.props.topVisibleLayer;
        const clock = this.props.clock;

        if (curTopVisibleLayer !== undefined && (prevTopVisibleLayer === undefined || prevTopVisibleLayer.layers !== curTopVisibleLayer.layers) && curTopVisibleLayer.isTimeSeries) {

            const start = JulianDate.fromIso8601(curTopVisibleLayer.times.get(0).data.Time);
            const stop = JulianDate.fromIso8601(curTopVisibleLayer.times.get(curTopVisibleLayer.times.length - 1).data.Time);
            clock.startTime = start;
            clock.stopTime = stop;
            clock.currentTime = curTopVisibleLayer.defaultTime || start;
            clock.clockRange = ClockRange.LOOP_STOP;
            clock.multiplier = curTopVisibleLayer.multiplier;
             this.zoom();
        }
    }

    componentDidMount() {

        this.cesiumTimeline = new WrappedTimeline(
            this.timelineContainer,
            this.props.clock
        );


        this.props.passTimeline(this.cesiumTimeline);

        this.cesiumTimeline.makeLabel = time => {
            if (this.props.topVisibleLayer.isTimeSeries || this.props.topVisibleLayer.timelineTic==='yyyy') {
                // const layer = this.props.topVisibleLayer;

                return dateFormat(
                    JulianDate.toDate(time),
                    "yyyy-mm-dd"
                );

            }

            // Adjust the label format as you zoom by using the visible timeline's start and end
            // (not the fixed this.props.terria.clock.startTime and stopTime).

            const startJulian = this.cesiumTimeline._startJulian;
            const endJulian = this.cesiumTimeline._endJulian;
            const totalDays = JulianDate.daysDifference(endJulian, startJulian);

            if (totalDays > 14) {
                return formatMonth(JulianDate.toDate(time));
            } else if (totalDays < 1) {
                return formatTime(JulianDate.toDate(time));
            }

            return formatDateTime(JulianDate.toDate(time));
        };

        this.cesiumTimeline.scrubFunction = e => {
            const clock = e.clock;
            clock.currentTime = e.timeJulian;
            clock.shouldAnimate = false;

        };

        this.cesiumTimeline.addEventListener(
            "settime",
            this.cesiumTimeline.scrubFunction,
            false
        );


        // this.cesiumTimeline.addEventListener(
        //     "setzoom",
        //     e=>{
        //         const clock = e.clock;
        //         console.log()
        //
        //         console.log(dateFormat(JulianDate.toDate(e.startJulian), "yyyy-mm"))
        //         console.log(dateFormat(JulianDate.toDate(e.endJulian), "yyyy-mm"))
        //
        //     },false
        // )

        let curTopVisibleLayer = this.props.topVisibleLayer;

        if (curTopVisibleLayer.isTimeSeries) {
            const clock = this.props.clock;
            const start = JulianDate.fromIso8601(curTopVisibleLayer.times.get(0).data.Time);
            const stop = JulianDate.fromIso8601(curTopVisibleLayer.times.get(curTopVisibleLayer.times.length - 1).data.Time);
            clock.startTime = start;
            clock.stopTime = stop;
            clock.currentTime = curTopVisibleLayer.defaultTime || start;
            clock.multiplier = curTopVisibleLayer.multiplier;
            clock.clockRange = ClockRange.LOOP_STOP;
            this.zoom();

        }

    }

    zoom = () => {

        this.cesiumTimeline.zoomTo(
            this.props.clock.startTime,
            this.props.clock.stopTime
        );


    }

shouldComponentUpdate(nextProps, nextState, nextContext) {
       return true;
}

    render() {
        return (
            <div
                className={"cesiumTimeline"}
                ref={ref => {
                    this.timelineContainer = ref;
                }}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        clock: (state => state.layers.clock)(state),
        topVisibleLayer: getTopVisibleLayer(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(layerActions, dispatch),
    };
};


export default hot(connect(mapStateToProps, mapDispatchToProps)(CesiumTimeline))
