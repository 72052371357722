import React from "react";
import {hot} from "react-hot-loader/root";
import ClockRange from "cesium/Source/Core/ClockRange";
import "./timeline-controls.css";
import {BackToStartIcon, BackwardIcon, ForwardIcon, PauseIcon, PlayIcon} from "../../utils";


class TimelineControls extends React.Component {


    gotoStart = () => {

        this.props.clock.currentTime = this.props.clock.startTime;

    }

    togglePlay = () => {
        if (this.props.layer.multiplier){
            this.props.clock.multiplier = this.props.layer.multiplier
        }
        this.props.clock.shouldAnimate = !this.props.clock.shouldAnimate;

    }

    playBackward = () => {
        this.props.revert()
        this.props.clock.tick();
        this.props.clock.multiplier = -Math.abs(this.props.layer.multiplier);
        this.props.clock.shouldAnimate = true;

    }

    playForward = () => {
        this.props.revert()
        this.props.clock.tick();
        this.props.clock.multiplier = Math.abs(this.props.layer.multiplier);
        this.props.clock.shouldAnimate = true;

    }


    isLooping = () => {
        return this.props.clock.clockRange === ClockRange.LOOP_STOP;
    }

    isPlaying = () => {
        return this.props.clock.shouldAnimate;
    }

    render() {
        return (
            <div className={"controls"}>
                <button
                    type="button"
                    className={"timeline__control btn"}
                    onClick={this.gotoStart}
                    title="起点"
                >
                    <BackToStartIcon/>
                </button>
                <button
                    disabled={this.props.layer.isTimeSeries===undefined}
                    type="button"
                    className={"timeline__control btn"}
                    onClick={this.playBackward}
                    title="倒放"
                >
                    <BackwardIcon/>
                </button>
                <button
                    disabled={this.props.layer.isTimeSeries===undefined}
                    type="button"
                    className={"timeline__control btn"}
                    onClick={this.playForward}
                    title="正放"
                >
                    <ForwardIcon/>
                </button>
                <button
                    type="button"
                    className={"timeline__control btn"}
                    onClick={this.togglePlay}
                    title={this.isPlaying() ? "暂停" : "播放"}
                >
                    {this.isPlaying() ? (
                        <PauseIcon/>
                    ) : (
                        <PlayIcon/>
                    )}
                </button>

            </div>
        );
    }
}

export default hot(TimelineControls)