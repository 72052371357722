/**
 * 播放ged时序数据的进度条
 */

import React, {useEffect, useState} from 'react';
import {styled, withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

const PositionContainer = styled('div')({
    position: 'absolute',
    zIndex: 1,
    bottom: '40px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const SliderInput = withStyles({
    root: {
        marginLeft: 12,
        width: '40%'
    },
    valueLabel: {
        '& span': {
            fontSize:'18pt',
            whiteSpace: 'nowrap',
            background: 'none',
            color: '#ff8800'
        }
    }
})(Slider);

export default function RangeInput({min, max, value, animationSpeed, onChange, formatLabel}) {
    const [isPlaying, setIsPlaying] = useState(false);

    // prettier-ignore
    useEffect(() => {
        let animation;

        if (isPlaying) {
            animation = requestAnimationFrame(() => {
                let nextValue = value + animationSpeed;
                if (nextValue > max) {
                    nextValue = min;
                }
                onChange(nextValue);
            });
        }

        return () => animation && cancelAnimationFrame(animation);
    });

    return (
        <PositionContainer>
            <Button color="secondary" onClick={() => setIsPlaying(!isPlaying)}>
                {isPlaying ? <PauseIcon title="Stop"/> : <PlayIcon title="Animate"/>}
            </Button>
            <SliderInput
                color={"secondary"}
                min={min}
                max={max}
                value={value}
                onChange={(event, newValue) => onChange(newValue)}
                valueLabelDisplay="on"
                valueLabelFormat={formatLabel}
            />
        </PositionContainer>
    );
}
