/*
 * Created at 2021/3/22 下午8:27 by lhq
 */

import './Register.css'
import React, {Component} from "react";
import {Form, Input, Button, Checkbox, Row, Col, message, Layout, Menu, notification} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {Redirect} from 'react-router-dom'

import {registerActions} from '../../redux/modules/register'
import {post, get, SPRING_HOST} from "../utils";


const {Header, Content, Footer} = Layout;


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 18,
        },
    },
};


const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 18,
            offset: 6,
        },
    },
};

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            coolDown: 0
        }
    }

    formRef = React.createRef();


    componentDidMount() {


    }

    sendVerifyCode = () => {

        if (this.formRef.current.getFieldError("email").length === 0) {
            // 邮箱输入正确
            // 查询邮箱是否存在

            let email = this.formRef.current.getFieldValue("email")

            get(`${SPRING_HOST}/user/emailExist?email=${email}`).then(
                res => {

                    if (!res.data) {
                        notification["error"]({
                            message: '错误',
                            description: '此邮箱未注册，请修改后重试',
                        });

                    } else {
                        // 存在，发送邮件验证码
                        get(`${SPRING_HOST}/user/resetPwdVerifyEmail?email=${email}`).then(
                            res => {

                                if (res.data) {
                                    notification["success"]({
                                        message: '发送成功',
                                        description: '验证码已发送到邮箱，请前往邮箱中复制到这里',
                                    });

                                    this.setState({
                                        coolDown: 60
                                    })

                                    this.resetCoolDown();

                                } else {
                                    notification["error"]({
                                        message: '注册失败',
                                        description:
                                        res.msg,
                                    });


                                }

                            }
                        )
                    }

                }
            )
        }


    }

    resetCoolDown = () => {

        let interval = setInterval(() => {
                let newCoolDown = this.state.coolDown - 1;
                this.setState({
                    coolDown: newCoolDown
                })

                if (newCoolDown < 1) {
                    clearInterval(this.state.interval);
                    this.setState({interval: undefined})
                }
            }
            , 1000)

        this.setState({
            interval
        })

    }

    onFinish = (values) => {

        let resetPasswordDto = {

            "email": values.email,
            'verifyCode': values.verifyCode,
            "newPassword": values.newPassword
        };

        console.log(resetPasswordDto)


        post(`${SPRING_HOST}/user/resetPassword`, resetPasswordDto).then(
            res => {

                if (res.data) {
                    notification["success"]({
                        message: '重置成功',
                        description: '密码已成功重置',
                    });

                    localStorage.removeItem("ydyl.loginStorage")

                    setTimeout(() => {
                        window.location.href = window.location.href.replace("/user/resetPassword", "login")
                    }, 5000);

                } else {
                    notification["error"]({
                        message: '重置失败',
                        description:
                        res.msg,
                    });

                }

            }
        )
    };


    render() {
        return (
            <div className={"register"}>
                <Row>
                    <div className={"logo"} style={{"margin-left": "30px"}}/>
                    <h2 style={{"margin": "auto 10px", color: "white"}}>中科带路：绿色丝绸之路决策支持</h2>
                </Row>
                <Row>
                    <Col span={15}>

                    </Col>
                    <Col span={8}>
                        <div className={"mod-reg"}>
                            <Row>
                                <h2>重置密码</h2>
                            </Row>

                            <Row>

                            </Row>
                            <Form
                                {...formItemLayout}
                                ref={this.formRef}

                                name="register"
                                onFinish={this.onFinish}
                                scrollToFirstError
                            >

                                <Form.Item
                                    name="email"
                                    label="邮箱"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: '请输入正确的邮箱',
                                        },
                                        {
                                            required: true,
                                            message: '请输入邮箱',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item

                                    label="*验证码"
                                    >
                                    <Input.Group compact>
                                        <Form.Item
                                            name="verifyCode"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入验证码',
                                                },
                                            ]}>
                                            <Input style={{width: 'calc(100% - 10px)'}}/>
                                        </Form.Item>
                                        <Button
                                            onClick={() => this.sendVerifyCode()}
                                            style={{"color": "grey"}}
                                            type="primary"
                                            disabled={this.state.coolDown > 0}>{this.state.coolDown === 0 ? '获取' : this.state.coolDown}</Button>
                                    </Input.Group>
                                </Form.Item>

                                <Form.Item
                                    name="newPassword"
                                    label="新密码"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入密码',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password/>
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label="确认密码"
                                    dependencies={['newPassword']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: '再次输入新密码',
                                        },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error('两次密码输入不一致!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>


                                <Form.Item {...tailFormItemLayout}>
                                    <Button type="primary" htmlType="submit" style={{"width": "100%", "color": "grey"}}>
                                        提交
                                    </Button>
                                </Form.Item>
                            </Form>

                            <Row align={"end"}>
                                <h4>知道密码? <a href={"/login"}>去登录</a></h4>
                            </Row>

                        </div>
                    </Col>

                    <Col span={8}>

                    </Col>
                </Row>
                <div style={{position: "fixed", left: "20px", bottom: "3px"}}><h4>&nbsp;&nbsp;版权所有 © 中国科学院地理科学与资源研究所 |
                    备案序号：<a style={{color: "deepskyblue"}}
                            href="https://beian.miit.gov.cn/"
                            target="_blank">京ICP备05002838号-59</a>
                </h4></div>
            </div>

        )
    }

}

const mapStateToProps = (state, props) => {
    return {
        shouldRedirect: (state => state.login.shouldRedirect)(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(registerActions, dispatch)
    }
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
