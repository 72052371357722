// export const riskTreeData = [
//
//     {
//         "key": "2020年丝路沿线国家主权信用指数",
//         "alias": "2020年丝路沿线国家主权信用指数"
//     },
//     {
//
//         "key": "2020年丝路沿线国家债务风险",
//         "alias": "2020年丝路沿线国家债务风险"
//     }, {
//
//         "key": "2020年丝路沿线国家社会稳定度评估",
//         "alias": "2020年丝路沿线国家社会稳定度"
//     },
//
//     {
//
//         "key": "2020年丝路沿线国家社会治理能力",
//         "alias": "2020年丝路沿线国家社会治理能力"
//     },
//     {
//
//         "key": "2020年丝路沿线国家地缘安全度",
//         "alias": "2020年丝路沿线国家地缘安全度"
//     }, {
//
//         "key": "2020年丝路沿线国家社会复杂度评估",
//         "alias": "2020年丝路沿线国家社会复杂度",
//     },
//     {
//
//         "key": "2020年中国投资环境",
//         "alias": "2020年中国投资环境"
//     }, {
//         "type": "mapbox",
//         "key": "ged201",
//         "alias": "GED全球冲突事件"
//     },
//     {
//         "type": "mapbox",
//         "key": "ged201_fullTime",
//         "alias": "1989-2019年全球冲突事件累计"
//     },
//
//
//     {
//
//         "key": "一带一路全域环境综合承载力",
//         "alias": "2019年丝路沿线环境综合承载力"
//     }, {
//
//         "key": "一带一路沿线国家环境风险",
//         "alias": "2016年丝路沿线国家环境安全度",
//     }, {
//
//         "key": "一带一路全域综合风险",
//         "alias": "2019年丝路沿线综合灾害风险"
//     }, {
//         "key": "2019年丝路沿线国家冲突事件",
//         "alias": "2019年丝路沿线国家冲突事件"
//     },{
//
//         "key": "2020年丝路沿线国家碳排放空间指数",
//         "alias": "2020年丝路沿线国家碳排放空间指数",
//     }
// ]


// export const monitorTreeData = [
//     {
//
//         "key": "中白工业园",
//         "alias": "中白工业园",
//         "extraInfo": true
//
//     }, {
//
//         "key": "青岛印尼综合产业园",
//         "alias": "青山印尼综合产业园",
//         "extraInfo": false
//     }, {
//
//         "key": "文莱大摩拉岛石油炼化工业园",
//         "alias": "文莱大摩拉岛石油炼化工业园",
//         "extraInfo": false
//     }, {
//
//         "key": "缅甸莱比塘铜矿",
//         "alias": "缅甸莱比塘铜矿",
//         "extraInfo": false
//     }, {
//
//         "key": "乌兹别克斯坦鹏盛工业园",
//         "alias": "乌兹别克斯坦鹏盛工业园",
//         "extraInfo": false
//     }, {
//
//         "key": "埃及苏伊士经贸合作区",
//         "alias": "埃及苏伊士经贸合作区",
//         "extraInfo": false
//     }, {
//
//         "key": "俄罗斯乌苏里斯克经贸区",
//         "alias": "俄罗斯乌苏里斯克经贸区",
//         "extraInfo": false
//     }, {
//
//         "key": "中哈霍尔果斯国际边境合作中心",
//         "alias": "中哈霍尔果斯国际边境合作中心",
//         "extraInfo": false
//     }, {
//
//         "key": "科伦坡港口城",
//         "alias": "科伦坡港口城",
//         "extraInfo": false
//     }, {
//
//         "key": "中国阿曼轻工业与综合园区",
//         "alias": "中国阿曼轻工业与综合园区",
//         "extraInfo": false
//     }, {
//
//         "key": "中国阿曼重工业园区",
//         "alias": "中国阿曼重工业园区",
//         "extraInfo": false
//     }, {
//
//         "key": "中国阿曼旅游园区",
//         "alias": "中国阿曼旅游园区",
//         "extraInfo": false
//     }, {
//
//         "key": "老挝万象赛色塔综合开发区",
//         "alias": "老挝万象赛色塔综合开发区",
//         "extraInfo": false
//     }, {
//
//         "key": "塔班陶勒盖煤矿",
//         "alias": "塔班陶勒盖煤矿",
//         "extraInfo": false
//     }, {
//
//         "key": "巴基斯坦M-4高速公路",
//         "alias": "巴基斯坦M-4高速公路",
//         "extraInfo": false
//     }, {
//
//         "key": "中匈宝思德经贸合作区",
//         "alias": "中匈宝思德经贸合作区",
//         "extraInfo": false
//     }, {
//
//         "key": "印度尼西亚青山工业园",
//         "alias": "印度尼西亚青山工业园",
//         "extraInfo": false
//     }, {
//
//         "key": "吉大港",
//         "alias": "吉大港",
//         "extraInfo": false
//     }, {
//
//         "key": "希腊比雷埃夫斯港",
//         "alias": "希腊比雷埃夫斯港",
//         "extraInfo": false
//     }, {
//
//         "key": "越南龙江工业园",
//         "alias": "越南龙江工业园",
//         "extraInfo": false
//     }, {
//
//         "key": "柬埔寨西港特区",
//         "alias": "柬埔寨西港特区",
//         "extraInfo": false
//     }, {
//
//         "key": "中老磨憨.磨丁跨境经济区",
//         "alias": "中老磨憨.磨丁跨境经济区",
//         "extraInfo": false
//     }, {
//
//         "key": "迪拜哈翔清洁能源电站项目",
//         "alias": "迪拜哈翔清洁能源电站项目",
//         "extraInfo": false
//     }, {
//         "key": "其他项目矢量范围数据",
//         "children": [{
//
//             "key": "中国-老挝-泰国铁路",
//             "alias": "中老泰国铁路",
//             "extraInfo": false
//         }, {
//
//             "key": "匈牙利-塞尔维亚铁路",
//             "alias": "匈塞铁路",
//             "extraInfo": false
//         }, {
//
//             "key": "雅加达-万隆高速铁路",
//             "alias": "雅万铁路",
//             "extraInfo": false
//         }, {
//
//             "key": "白卡高速",
//             "alias": "白卡高速",
//             "extraInfo": false
//         }, {
//
//             "key": "马中关丹产业园",
//             "alias": "马中关丹产业园",
//             "extraInfo": false
//         }, {
//
//             "key": "马六甲临海工业园",
//             "alias": "马六甲临海工业园",
//             "extraInfo": false
//         }, {
//
//             "key": "汉班托塔港口",
//             "alias": "汉班托塔港口",
//             "extraInfo": false
//         }, {
//             "key": "巴基斯坦卡洛特水电站",
//             "alias": "巴基斯坦卡洛特水电站",
//             "extraInfo": false
//         }
//         ]
//     }
// ]

export const table13ShareLayerSet = new Set([
    "一带一路全域环境综合承载力",
    "一带一路沿线国家环境风险",
    "一带一路全域综合风险",
    "2020年丝路沿线国家主权信用指数",
    "2019年丝路沿线国家冲突事件",
    "2020年丝路沿线国家债务风险",
    "2020年丝路沿线国家社会稳定度评估",
    "2020年丝路沿线国家社会治理能力",
    "2020年丝路沿线国家经济对外依赖度",
    "2020年丝路沿线国家社会复杂度评估",
    "2020年中国投资环境",
    "2018年丝路沿线国家经济对外依赖度",
    "2020年丝路沿线国家碳排放空间指数",
])

export const tab23ShareLayerSet = new Set([

])


export const tab2onlyLayerSet = new Set([

    "项目监测",
    "中白工业园",
    "青岛印尼综合产业园",
    "文莱大摩拉岛石油炼化工业园",
    "缅甸莱比塘铜矿",
    "乌兹别克斯坦鹏盛工业园",
    "埃及苏伊士经贸合作区",
    "俄罗斯乌苏里斯克经贸区",
    "泰国罗勇工业园",
    "中哈霍尔果斯国际边境合作中心",
    "科伦坡港口城",
    "中国阿曼轻工业与综合园区",
    "中国阿曼重工业园区",
    "中国阿曼旅游园区",
    "老挝万象赛色塔综合开发区",
    "塔班陶勒盖煤矿",
    "巴基斯坦M-4高速公路",
    "中匈宝思德经贸合作区",
    "印度尼西亚青山工业园",
    "吉大港",
    "希腊比雷埃夫斯港",
    "越南龙江工业园",
    "柬埔寨西港特区",
    "中老磨憨.磨丁跨境经济区",
    "迪拜哈翔清洁能源电站项目",
    "其他项目矢量范围数据",
    "中国-老挝-泰国铁路",
    "匈牙利-塞尔维亚铁路",
    "雅加达-万隆高速铁路",
    "白卡高速",
    "马中关丹产业园",
    "马六甲临海工业园",
    "汉班托塔港口",
    "巴基斯坦卡洛特水电站",
    "2018-2019丝路沿线地缘安全度"
])
// export const autoZoomToLayers = new Set([
//     "迪拜哈翔清洁能源电站项目",
//     "中老磨憨.磨丁跨境经济区",
//     "柬埔寨西港特区",
//     "越南龙江工业园",
//     "希腊比雷埃夫斯港",
//     "吉大港",
//     "泰国罗勇工业园",
//     "印度尼西亚青山工业园",
//     "中匈宝思德经贸合作区",
//     "巴基斯坦M-4高速公路",
//     "塔班陶勒盖煤矿",
//     "中国阿曼旅游园区",
//     "中国阿曼重工业园区",
//     "中国阿曼轻工业与综合园区",
//     "科伦坡港口城",
//     "中哈霍尔果斯国际边境合作中心",
//     "俄罗斯乌苏里斯克经贸区",
//     "埃及苏伊士经贸合作区",
//     "乌兹别克斯坦鹏盛工业园",
//     "缅甸莱比塘铜矿",
//     "青岛印尼综合产业园",
//     "伊斯兰堡",
//     "阿克套",
//     "瓜达尔",
//     "阿巴斯港",
//     "阿尔马雷克",
//     "阿克托别",
//     "阿拉木图",
//     "阿什哈巴德",
//     "阿斯塔肯",
//     "埃基巴斯图兹",
//     "奥什市",
//     "巴尔坎纳",
//     "巴甫洛达尔",
//     "比什凯克",
//     "彼得罗巴甫尔",
//     "布哈拉",
//     "达沙古兹",
//     "迪拜",
//     "杜尚别市",
//     "厄斯克门",
//     "费尔干纳",
//     "海防",
//     "浩罕",
//     "河内",
//     "胡志明",
//     "吉扎克",
//     "贾拉拉巴德州",
//     "皎漂",
//     "卡尔希",
//     "卡拉奇",
//     "科克舍套",
//     "克孜勒奥尔达",
//     "苦盏",
//     "库尔干秋别",
//     "库利亚布",
//     "阔斯塔奈",
//     "老街",
//     "马尔吉兰",
//     "马雷",
//     "马六甲港",
//     "曼德勒",
//     "曼谷港",
//     "芒街",
//     "木姐",
//     "纳曼干",
//     "纳沃伊",
//     "努库斯",
//     "奇尔奇克",
//     "奇姆肯特",
//     "热兹卡兹甘",
//     "撒马尔罕",
//     "赛梅伊",
//     "塔尔迪库",
//     "塔什干",
//     "帖木尔套",
//     "铁尔梅兹",
//     "同登",
//     "突厥斯坦",
//     "乌尔根奇",
//     "乌拉尔",
//     "西哈努克市",
//     "新加坡港",
//     "雅加达",
//     "仰光",
//     "伊斯坦布尔",
//     "阿特劳",
//     "安集延",
//     "金边",
//     "卡拉干达",
//     "林查班",
//     "塔拉兹",
//     "土库曼纳巴德",
//     "安集延纺织园区",
//     "哈萨克斯坦农产品加工物流园区",
//     "海尔鲁巴经济区",
//     "吉尔吉斯斯坦亚洲之星农业产业合作区",
//     "吉尔吉斯斯坦中大工业园区",
//     "老挝磨丁经济开发专区",
//     "老挝万象赛色塔综合开发区",
//     "老中甘蒙钾盐综合开发区",
//     "奇瑞汽车产业园",
//     "泰国湖南工业园",
//     "泰中罗勇工业园",
//     "中哈金土地高科技产业园区",
//     "中塔（河南）农业产业科技示范园区",
//     "中塔和中泰新建新丝路农业产业合作区",
//     "中塔工业园区",
//     "瓜达尔自由区",
//     "Iran",
//     "Kazakhstan",
//     "Kyrgyzstan",
//     "Laos",
//     "Myanmar",
//     "Nepal",
//     "Pakistan",
//     "Tajikistan",
//     "Thailand",
//     "Turkmenistan",
//     "Uzbekistan",
//     "Vietnam",
//     "中缅经济带影像",
//     "中巴经济带影像",
//     "南亚海洋带影像",
//     "中白工业园",
//     "文莱大摩拉岛石油炼化工业园",
//     "中白工业园",
//     "青岛印尼综合产业园",
//     "文莱大摩拉岛石油炼化工业园",
//     "缅甸莱比塘铜矿",
//     "乌兹别克斯坦鹏盛工业园",
//     "埃及苏伊士经贸合作区",
//     "俄罗斯乌苏里斯克经贸区",
//     "中哈霍尔果斯国际边境合作中心",
//     "科伦坡港口城",
//     "中国阿曼轻工业与综合园区",
//     "中国阿曼重工业园区",
//     "中国阿曼旅游园区",
//     "老挝万象赛色塔综合开发区",
//     "塔班陶勒盖煤矿",
//     "巴基斯坦M-4高速公路",
//     "中匈宝思德经贸合作区",
//     "印度尼西亚青山工业园",
//     "吉大港",
//     "希腊比雷埃夫斯港",
//     "越南龙江工业园",
//     "柬埔寨西港特区",
//     "中老磨憨.磨丁跨境经济区",
//     "迪拜哈翔清洁能源电站项目",
//     "中国-老挝-泰国铁路",
//     "匈牙利-塞尔维亚铁路",
//     "雅加达-万隆高速铁路",
//     "白卡高速",
//     "马中关丹产业园",
//     "马六甲临海工业园",
//     "汉班托塔港口",
//     "密松水电站项目",
//     "巴基斯坦卡洛特水电站"
// ])
