/*
 * Created at 2022/1/14 下午9:23 by lhq
 */

import './GedViewer.css';
import React, {Component} from 'react';
import MapGL, {Layer, Source} from 'react-map-gl';
import {SPRING_HOST} from "../utils";

import {MapboxStyleSwitcherControl} from "mapbox-gl-style-switcher";
import "mapbox-gl-style-switcher/styles.css";
import LegendPanel from "../widget/LegendPanel";

const canvasSize = 500;

const styles = [
    {
        title: "深色地图",
        uri: `${SPRING_HOST}/geoq-dark.json`
    },
    {
        title: "浅色地图",
        uri: `${SPRING_HOST}/geoq-light.json`
    },
    {
        title: "标准地图",
        uri: `${SPRING_HOST}/amap.json`
    },
    {
        title: "卫星地图",
        uri: `${SPRING_HOST}/image.json`
    }
];

const styleControl = new MapboxStyleSwitcherControl(styles, '标准地图');


function Particle(x, y, xs, ys) {
    this.x = x;
    this.y = y;
    this.xs = xs;
    this.ys = ys;
    this.life = 0;
}

const particles = [];

function update(stage, mouseX, mouseY, green = 50, speed = 3, size = 30, max = 60) {

    //Adds ten new particles every frame
    for (var i = 0; i < 10; i++) {

        //Adds a particle at the mouse position, with random horizontal and vertical speeds
        var p = new Particle(mouseX, mouseY, (Math.random() * 2 * speed - speed) / 2, 0 - Math.random() * 2 * speed);
        particles.push(p);
    }

    //Clear the stage so we can draw the new frame
    stage.clearRect(0, 0, canvasSize, canvasSize);

    //Cycle through all the particles to draw them
    for (i = 0; i < particles.length; i++) {

        //Set the file colour to an RGBA value where it starts off red-orange, but progressively gets more grey and transparent the longer the particle has been alive for
        stage.fillStyle = "rgba(" + (260 - (particles[i].life * 2)) + "," + ((particles[i].life * 2) + green) + "," + (particles[i].life * 2) + "," + (((max - particles[i].life) / max) * 0.4) + ")";

        stage.beginPath();
        //Draw the particle as a circle, which gets slightly smaller the longer it's been alive for
        stage.arc(particles[i].x, particles[i].y, (max - particles[i].life) / max * (size / 2) + (size / 2), 0, 2 * Math.PI);
        stage.fill();

        //Move the particle based on its horizontal and vertical speeds
        particles[i].x += particles[i].xs;
        particles[i].y += particles[i].ys;

        particles[i].life++;
        //If the particle has lived longer than we are allowing, remove it from the array.
        if (particles[i].life >= max) {
            particles.splice(i, 1);
            i--;
        }
    }
}

function factory(map, green) {
    return {
        width: canvasSize,
        height: canvasSize,
        data: new Uint8Array(canvasSize * canvasSize * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
            canvas.width = canvasSize;
            canvas.height = canvasSize;
            canvas.style.width = canvasSize + "px";
            canvas.style.height = canvasSize + "px";
            // stage.globalCompositeOperation = "lighter"
        },

        // Call once before every frame where the icon will be used.
        render: function () {
            const stage = this.context;
            stage.globalCompositeOperation = "lighter";
            update(stage, canvasSize / 2, canvasSize * 0.8, green)

            // Update this image's data with data from the canvas.
            this.data = stage.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;
            // Continuously repaint the map, resulting in the smooth animation of the dot.
            map.triggerRepaint();
            // Return `true` to let the map know that the image was updated.
            return true;
        }
    };
}

class GedFullTimeViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,

            hoverInfo: null,
            popupInfo: null,
            viewport: {
                latitude: 30.832703,
                longitude: 35.559081,
                minZoom: 2,
                zoom: 2
            },
            layer: "heatmap",
        }
    }


    componentDidMount() {

        let map = undefined;

        if (this.mapRef) {
            map = this.mapRef.getMap();
            map.addControl(styleControl, 'top-right');

        }

        if (map) {

            const pulsingDot1 = factory(map, 80)
            const pulsingDot2 = factory(map, 60)
            const pulsingDot3 = factory(map, 40)
            const pulsingDot4 = factory(map, 20)
            const pulsingDot5 = factory(map, 0)

            if (!map.hasImage('map-pin')) {

                map.addImage('pulsing-dot-1', pulsingDot1, {pixelRatio: 2});
                map.addImage('pulsing-dot-2', pulsingDot2, {pixelRatio: 2});
                map.addImage('pulsing-dot-3', pulsingDot3, {pixelRatio: 2});
                map.addImage('pulsing-dot-4', pulsingDot4, {pixelRatio: 2});
                map.addImage('pulsing-dot-5', pulsingDot5, {pixelRatio: 2});
            }

        }

    }


    render() {
        return (
            <div className={"mapPanel"}>
                <MapGL
                    ref={(ref) => this.mapRef = ref}
                    {...this.state.viewport}
                    width="100%"
                    height="100vh"
                    // mapboxApiAccessToken={"pk.eyJ1IjoibGhxIiwiYSI6ImNqeXNtc3AyeTBsdjczcG83bGlqaGhmemkifQ.s6_0cniYGuwv3dT4ahGwsA"}
                    mapStyle={`${SPRING_HOST}/dark.json`}
                    onViewportChange={(viewport) => this.setState({viewport})}

                    // interactiveLayerIds={this.props.activeLayer && [this.props.activeLayer]}

                    onHover={(event) => {
                        const {
                            features,
                            srcEvent: {offsetX, offsetY}
                        } = event;
                        const hoveredFeature = features && features[0];

                        if (hoveredFeature) {
                            this.setState({
                                hoverInfo: {
                                    feature: hoveredFeature,
                                    x: offsetX,
                                    y: offsetY
                                }
                            })
                        } else {
                            this.setState({
                                hoverInfo: null
                            })
                        }
                    }}
                >


                    {this.state.layer === 'heatmap' &&
                    <Source id={"heatmap"} type='vector'
                            tiles={[`${SPRING_HOST}/ged/{z}/{x}/{y}`]}>

                        <Layer id={"asdfxxgv"} {...{
                            'type': 'symbol',
                            'source-layer': 'aggs',
                            'minzoom': 1,
                            'layout': {
                                'icon-image': [
                                    'step',
                                    ['get', '_count'],
                                    'pulsing-dot-1',
                                    20, 'pulsing-dot-1',
                                    40, 'pulsing-dot-2',
                                    60, 'pulsing-dot-3',
                                    80, 'pulsing-dot-4',
                                    100, 'pulsing-dot-5',
                                ],
                                "icon-size": [
                                    'step',
                                    ['get', '_count'],
                                    0.1, 10,
                                    0.2, 20,
                                    0.4, 40,
                                    0.6, 60,
                                    0.8, 80,
                                    1.0
                                ],

                            },
                            // 'paint': {
                            //     'icon-opacity': [
                            //         'interpolate',
                            //         ['linear'],
                            //         ['zoom'],
                            //         6,
                            //         0,
                            //         10,
                            //         1]
                            // }
                        }}/>

                    </Source>}
                    {this.state.layer === "grid" &&
                    <Source id={"grid"} type='vector'
                            tiles={[`${SPRING_HOST}/ged/{z}/{x}/{y}`]}>

                        <Layer id={"grid"} {...{
                            type: 'fill',
                            'source-layer': 'aggs',
                            paint: {
                                'fill-color': [
                                    'step',
                                    ['get', '_count'],
                                    '#f2c5a6', 10,
                                    '#e69a7a', 20,
                                    '#db6e51', 50,
                                    '#cf3c2c', 100,
                                    '#c40a0b'

                                ],
                                'fill-outline-color': '#575757',
                                'fill-opacity': 0.8
                            }
                        }}/>


                        {
                            this.state.hoverInfo && (<div className="tooltip" style={{
                                left: this.state.hoverInfo.x,
                                top: this.state.hoverInfo.y
                            }}>
                                <div>数量: {this.state.hoverInfo.feature.properties._count}</div>
                            </div>)
                        }


                    </Source>
                    }


                </MapGL>

                <LegendPanel value={this.state.layer} onChange={(event) => {

                    this.setState({layer: event.target.value})
                }}
                             items={[[
                                 

                             ], [
                                 {"color": "#f2c5a6", "label": "(0,10]"},
                                 {"color": "#e69a7a", "label": "(11,20]"},
                                 {"color": "#db6e51", "label": "(21,50]"},
                                 {"color": "#cf3c2c", "label": "(51,100]"},
                                 {"color": "#c40a0b", "label": "(101-"}


                             ]]}/>
            </div>
        )
    }
}

export default GedFullTimeViewer;
