/*
 * Created at 2020/12/24 下午12:58 by lhq
 */

import React, {Component} from "react";
import {Row, Col, Collapse, Switch, Empty, Modal} from 'antd';
import ActiveLayerListItem from "../widget/ActiveLayerListItem";
import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import SplitPane, {Pane} from "react-split-pane";
import {
    appActions
} from '../../redux/modules/app'

import {layerActions, getCheckedLayers} from "../../redux/modules/layers";
import "./ProjectMonitorTab.css"

import MonitorCatalog from "./MonitorCatalog";

import {DocIcon, ChartIcon, DeleteIcon, MoveUpIcon, SPRING_HOST} from "../utils";

import {tab2onlyLayerSet, tab23ShareLayerSet} from "../../config/layerConfig";

const {Panel} = Collapse;


/**
 * 项目监测
 */
class ProjectMonitorTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false
        }
    }


    buildPanel = (data) => {
        return data.map((item, index) => <Panel className={"site-collapse-custom-panel"} header={item.layer.name}
                                                key={item.layer.name}
                                                extra={this.genExtra(item, index)}>
            <Row>
                <Col span={24}>
                    <ActiveLayerListItem key={item.layer.name} index={index} checkedLayer={item}/>
                </Col>
            </Row>

        </Panel>)
    }

    genExtra = (item, index) => {
        const that = this
        const commonAction = [
            <DeleteIcon
                title={"移除图层"}
                className={"collapse-icon"}
                onClick={event => {
                    event.stopPropagation();
                    that.props.removeLayer(index)

                }}
            />,

            // <MoveDownIcon
            //     className={"collapse-icon"}
            //     onClick={event => {
            //         // If you don't want click extra trigger collapse, you can prevent this:
            //         event.stopPropagation();
            //         that.props.moveDownLayer(key)
            //     }}
            // />,
            <MoveUpIcon
                title={"置顶图层"}
                className={"collapse-icon"}
                onClick={event => {
                    event.stopPropagation();
                    that.props.raiseAsTopLayer(index)
                }}
            />,
            <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked checked={item.visible} size={"small"}
                    onChange={(checked, event) => {
                        event.stopPropagation();
                        that.props.toggleLayerVisibility(index)
                    }} style={{marginBottom: "7px"}}
                    title={'显示/隐藏：' + item.key}
            />]
        const drawChart = (<ChartIcon
            className={"collapse-icon"}
            onClick={event => {
                event.stopPropagation();

                that.props.toggleBottomDrawer(true)
                that.props.toggleLayerStyle(index, item.layerStyles[0])
                if (this.props.checkedLayers[index].drawEntity && this.props.showGL) {

                    this.props.fetchGLData(item.key)
                } else if (this.props.showGL) {
                    this.props.toggleShowGL(false)
                }

            }}
        />)
        return item.drawChart ? [drawChart, ...commonAction] : commonAction
    };


    viewLayerInfo = (layer) => {
        this.setState({
            modalVisible: true,
            layerName: layer
        })

    }

    render() {
        return (
            <>
                <Row gutter={[0, 24]} justify={"center"}>
                    <Col span={24}>
                        <MonitorCatalog key={"0"} viewLayerInfo={this.viewLayerInfo}/>
                    </Col>
                </Row>
                <Modal
                    className={"layer-info-modal"}
                    title={<span><DocIcon/>{this.state.layerName}</span>}
                    closable={true}
                    mask={false}
                    maskClosable={true}
                    getContainer={document.getElementById("contentContainer")}
                    width={document.body.clientWidth - 750}
                    bodyStyle={{backgroundColor: "#fff"}}
                    onCancel={e => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    footer={false}
                    style={{marginLeft: '600px'}}
                >
                    <iframe
                        style={{width: '100%', overflow: 'auto'}}
                        src={`${SPRING_HOST}/poster/${this.state.layerName}.html`}
                        width="100%"
                        height={"640px"}
                        frameBorder="0"
                    />
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        allRadarData: ((state) => state.app.allRadarData)(state),
        checkedLayers: getCheckedLayers(state),
        allRadarDrawerOpen: ((state) => state.app.allRadarDrawerOpen)(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(appActions, dispatch),
        ...bindActionCreators(layerActions, dispatch),
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(ProjectMonitorTab));
