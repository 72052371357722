/*
 * Created at 2020/5/3 下午7:43 by lhq
 */
import {createCesiumComponent} from  'resium'
import WebGLGlobeDataSource from './CustomWebGLGlobeDataSource'


var load = function (_a) {
    var element = _a.element, data = _a.data;
    element.load(data)

};


var ReWebGLGlobeDataSource = createCesiumComponent({
    name: "WebGLGlobeDataSource",
    create: function (context, props) {
        if (!context.dataSourceCollection)
            return;
        var element = new WebGLGlobeDataSource(props.name,props.shaders);
        if (props.clustering) {
            element.clustering = props.clustering;
        }
        if (typeof props.show === "boolean") {
            element.show = props.show;
        }

        if (props.seriesToDisplay){
            element.seriesToDisplay = props.seriesToDisplay
        }
        context.dataSourceCollection.add(element);
        if (props.data) {
            load({
                element: element,
                dataSources: context.dataSourceCollection,
                data: props.data,
                onLoad: props.onLoad,
                sourceUri: props.sourceUri,
                credit: props.credit,
            });
        }
        return element;
    },
    update: function (element, props, prevProps, context) {

        if (!props.data) {
            element.show = false;
        }
        else if (prevProps.show !== props.show) {
            element.show = typeof props.show === "boolean" ? props.show : true;

        }
        else if(prevProps.seriesToDisplay!==props.seriesToDisplay){

                element.seriesToDisplay = props.seriesToDisplay
        }
        if (context.dataSourceCollection &&
            props.data &&
            (prevProps.data !== props.data ||
                prevProps.sourceUri !== props.sourceUri ||
                prevProps.credit !== props.credit)) {

            element.shaders=props.shaders

            load({
                element: element,
                dataSources: context.dataSourceCollection,
                data: props.data,
                onLoad: props.onLoad,
                sourceUri: props.sourceUri,
                credit: props.credit,
            });
        }
    },
    destroy: function (element, context) {
        if (context.dataSourceCollection && !context.dataSourceCollection.isDestroyed()) {
            context.dataSourceCollection.remove(element);
            console.log("REMOVE_GL")
        }
    },
    provide: function (element) {
        return {
            dataSource: element,
        };
    },
    cesiumProps: ["clustering"],
    cesiumReadonlyProps: ["name"],
    cesiumEventProps: {
        onChange: "changedEvent",
        onError: "errorEvent",
        onLoading: "loadingEvent",
    },
});


export default ReWebGLGlobeDataSource
