/*
 * Created at 2020/11/26 下午8:26 by lhq
 */

import React from "react";
import {Drawer, Row, Col} from "antd";
import ReactEcharts from "echarts-for-react";

export function LineChartDrawer(props) {
    const {chartTitle, chartData, visible, toggleLineChartDrawer} = props;
    return (

        <Drawer
            className={"chart-drawer"}
            title={chartTitle}
            placement="bottom"
            height={400}
            maskClosable={false}
            mask={false}
            closable
            onClose={() => toggleLineChartDrawer(false)}
            visible={visible}
            getContainer="#contentContainer"
            style={{position: 'absolute'}}
        >

            {/*<Row style={{height: '90%'}} aligin={"center"}>*/}
            {/*    <Col span={24}>*/}
                    <ReactEcharts
                        option={chartData}
                        theme={"dark"}
                        className='react_for_echarts'
                        style={{height: '380px', width: '100%'}}/>
            {/*    </Col>*/}
            {/*</Row>*/}

        </Drawer>

    )
}