import './ChartDrawer.css'
import React, {Component} from "react";
import {Drawer, Row, Col, Checkbox, Select} from "antd";
import {Bar} from "react-chartjs-2";
import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


import {
    layerActions,
    getBottomDrawerOpen,
    getLayerMap,
    getCheckedLayers,
    getChartData
} from '../../redux/modules/layers'
import {appActions} from "../../redux/modules/app";


const options = {
    maintainAspectRatio: false,
    legend: {
        display: false,
        color: "#fff",
        fontColor: '#fff'
    },
    tooltips: {
        mode: 'nearest'
    },
    cornerRadius: 20,
    scales: {
        xAxes: [{
            ticks: {
                autoSkip: false,
                fontSize: 12,
                fontColor: "#fff"
            },
            gridLines: {
                display: false,
                drawBorder: true,
                color: "#aaa"
            }
        }],
        yAxes: [{
            gridLines: {
                display: true,
                drawBorder: true,
                color: "#666"
            },
            ticks: {
                fontSize: 12,
                fontFamily: "Montserrat",
                fontColor: "#fff"
            }
        }]
    }
};

const buildOptions = (styles) => styles.map(value => ({value}))

class ChartDrawer extends Component {

    onSelectLayerStyle = (style) => {
        const {chartLayer, checkedLayers} = this.props;
        let index = 0;
        for (index = 0; index < checkedLayers.length; index++) {
            if (checkedLayers[index].key === chartLayer.key)
                break;
        }
        this.props.toggleLayerStyle(index, style)
    }

    render() {
        const {chartData, chartTitle, chartLayer} = this.props
        return (
            <Drawer
                className={"chart-drawer"}
                title={chartTitle}
                placement="bottom"
                height={400}
                maskClosable={false}
                mask={false}
                closable
                onClose={() => this.props.toggleBottomDrawer(false)}
                visible={this.props.bottomDrawerOpen}
                getContainer="#contentContainer"
                // headerStyle={{backgroundColor: "#27282750", borderBottom: "none", padding: "5px 10px"}}
                style={{position: 'absolute'}}
            >
                <Row justify={"start"}>
                    {(this.props.chartLayer.layerStyles) ? (<Col span={4}>
                        <Select value={this.props.chartLayer.layerStyles[0]} options={buildOptions(this.props.chartLayer.layerStyles)}
                                onChange={this.onSelectLayerStyle} size={"small"}/>
                    </Col>) : (<Col span={6}/>)}
                    <Col span={3}>
                        <Checkbox checked={this.props.showGL} disabled={!this.props.chartLayer.layer || !this.props.chartLayer.layer.drawEntity}  onChange={e =>{
                            this.props.toggleShowGL(e.target.checked)
                            if (e.target.checked){
                                this.props.fetchGLData(chartLayer.key)
                            }
                        } } >
                            三维柱状图
                        </Checkbox>
                    </Col>

                </Row>

                <Row style={{height: '90%', paddingTop: '12px'}} aligin={"center"}>
                    <Col span={24}>
                        <Bar data={chartData} options={options}/>
                    </Col>
                </Row>

            </Drawer>
        )
    }

}

const mapStateToProps = (state, props) => {
    return {

        showGL: (state => state.layers.showGL)(state),
        chartLayer: (state => state.layers.chartLayer)(state),
        chartTitle: (state => state.layers.chartTitle)(state),
        chartData: getChartData(state),
        bottomDrawerOpen: getBottomDrawerOpen(state),
        layerLookup: getLayerMap(state),
        checkedLayers: getCheckedLayers(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(layerActions, dispatch),
        ...bindActionCreators(appActions, dispatch)
    };
};

export default hot(connect(mapStateToProps, mapDispatchToProps)(ChartDrawer));
