/*
 * Created at 2021/3/22 下午8:27 by lhq
 */

import './Register.css'
import React, {Component} from "react";
import {Form, Input, Button, Checkbox, Row, Col, message, Layout, Menu, notification} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {hot} from "react-hot-loader/root";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {Redirect} from 'react-router-dom'

import {registerActions} from '../../redux/modules/register'
import {post, SPRING_HOST} from "../utils";


const {Header, Content, Footer} = Layout;


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 18,
        },
    },
};


const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 18,
            offset: 6,
        },
    },
};

class Register extends Component {

    formRef = React.createRef();


    componentDidMount() {


    }

    onFinish = (values) => {

        let registerDto = {'name': values.nickname,'username':values.username, "email": values.email, "password": values.password};


        post(`${SPRING_HOST}/user/register`, registerDto).then(
            res => {

                if (res.data) {
                    notification["success"]({
                        message: '注册成功',
                        description: '请前往注册邮箱中点击链接验证邮箱，以完成注册。',
                    });

                    setTimeout(() => {
                        window.location.href = window.location.href.replace("register", "login")
                    }, 5000);

                } else {
                    notification["error"]({
                        message: '注册失败',
                        description:
                        res.msg,
                    });


                }

            }
        )
    };


    render() {
        return (
            <div className={"register"}>
                <Row>
                    <div className={"logo"} style={{"margin-left": "30px"}}/>
                    <h2 style={{"margin": "auto 10px", color: "white"}}>中科带路：绿色丝绸之路决策支持</h2>
                </Row>
                <Row>
                    <Col span={15}>

                    </Col>
                    <Col span={8}>
                        <div className={"mod-reg"}>
                            <Row>
                                <h2>欢迎注册</h2>
                            </Row>

                            <Row>

                            </Row>
                            <Form
                                {...formItemLayout}
                                ref={this.formRef}

                                name="register"
                                onFinish={this.onFinish}
                                scrollToFirstError
                            >
                                <Form.Item
                                    name="nickname"
                                    label="昵称"
                                    tooltip="请问您怎么称呼?"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入一个昵称',
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    name="username"
                                    label="用户名"
                                    rules={[
                                        {
                                            required: true,
                                            message: '用户名不能为空',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    label="邮箱"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: '请输入正确的邮箱',
                                        },
                                        {
                                            required: true,
                                            message: '请输入邮箱',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="密码"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入密码',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password/>
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label="确认密码"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: '请确认密码',
                                        },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error('两次密码输入不一致!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>


                                <Form.Item {...tailFormItemLayout}>
                                    <Button type="primary" htmlType="submit" style={{"width": "100%", "color": "grey"}}>
                                        立即注册
                                    </Button>
                                </Form.Item>
                            </Form>

                            <Row align={"end"}>
                                <h4>已有账号? <a href={"/login"}>去登录</a></h4>
                            </Row>

                        </div>
                    </Col>

                    <Col span={8}>

                    </Col>
                </Row>
                <div style={{position: "fixed", left: "20px", bottom: "3px"}}><h4>&nbsp;&nbsp;版权所有 © 中国科学院地理科学与资源研究所 |
                    备案序号：<a style={{color: "deepskyblue"}}
                            href="https://beian.miit.gov.cn/"
                            target="_blank">京ICP备05002838号-59</a>
                </h4></div>
            </div>

        )
    }

}

const mapStateToProps = (state, props) => {
    return {
        shouldRedirect: (state => state.login.shouldRedirect)(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(registerActions, dispatch)
    }
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(Register));
