/**
 * ged 图层的图例组件
 */
import React from "react";
import {Radio, Space} from "antd";

function LegendPanel({value, items, onChange}) {


    return (
        <div className={"map-overlay"} id={"legend"}>


            <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                    <Radio value={"heatmap"}>热力图</Radio>
                    <Radio value={"grid"}>网格图</Radio>
                </Space>
            </Radio.Group>
            <br/>
            {value === 'heatmap' ?  items[0].map(item => (
                <div key={item.label}>
                    <span className={'legend-key'} style={{
                        backgroundColor: item.color
                    }}/>
                    <span>{item.label}</span>
                </div>
            )) : items[1].map(item => (
                <div key={item.label}>
                    <span className={'legend-key'} style={{
                        backgroundColor: item.color
                    }}/>
                    <span>{item.label}</span>
                </div>
            ))}

        </div>
    )

}

export default LegendPanel;
