import {WebMapTileServiceImageryProvider, defined} from 'cesium'

const defaultOptions = {

    "tileMatrixSetID":"default028mm",
    "style":"default",
    "format":"image/jpeg",
}

class CustomWMTSImageryProvider extends WebMapTileServiceImageryProvider{
    constructor(options) {
        const {drawChart,multiplier,alias,customLegend} = options

        options = {...defaultOptions,...options}
        super(options);

        this.type = 'wmts'

        this.name = alias || this.layer

        // 本图层是否关联图表
        this.drawChart = drawChart || false

        this.customLegend = customLegend || false

        this.multiplier = multiplier

        this.isTimeSeries = defined(this._timeDynamicImagery)
    }
}

export default CustomWMTSImageryProvider
