/*
 * Created at 2021/12/31 下午5:17 by lhq
 */

/*
 * Created at 2020/6/3 下午8:12 by lhq
 */
import React, {Component} from "react";
import {Form, Input, Button, Checkbox, Row, Col, message} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import './login.css'
import {Viewer,} from "resium";
import {Redirect} from 'react-router-dom'
import {Cartesian3, Credit, UrlTemplateImageryProvider} from 'cesium'
import {loginActions} from "../../redux/modules/login";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {hot} from "react-hot-loader/root";

const capitals = [[116.3911167, 39.9165], [100.5010361, 13.75103889], [77.20588333, 28.61526111], [37.61704167, 55.75605278], [14.43735556, 50.07535], [55.265525, 25.20375556], [30.52338889, 50.45009167], [51.38897778, 35.68917222]]

const randomCapitals = () => capitals[Math.ceil(Math.random() * 7)];

class Login extends Component {

    doLogin = values => {
        console.log(values)
        this.props.doLogin(values)
    };

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    componentDidMount() {
        if (this.viewer) {
            const viewer = this.viewer
            viewer.scene.screenSpaceCameraController.minimumZoomDistance = 10000;//相机的高度的最小值
            viewer.scene.screenSpaceCameraController.maximumZoomDistance = 220000; //相机高度的最大值
            viewer.scene.screenSpaceCameraController._minimumZoomRate = 3000; // 设置相机缩小时的速率
            viewer.scene.screenSpaceCameraController._maximumZoomRate = 59063; //设置相机放大时的速
            viewer.camera.flyTo({
                duration: 10,
                destination: Cartesian3.fromDegrees(116.3911167, 39.9165, 100000.0)
            });
            this.interval = setInterval(() => {
                let capitalLngLat = randomCapitals();
                viewer.camera.flyTo({
                    destination: Cartesian3.fromDegrees(capitalLngLat[0], capitalLngLat[1], 10000.0),
                    duration: 12
                });
            }, 15000)


            // viewer.scene.frameState.creditDisplay.removeDefaultCredit(viewer.scene.frameState.creditDisplay._defaultCredits[0])
            viewer.scene.frameState.creditDisplay.addDefaultCredit(new Credit('<h4>&nbsp;&nbsp;版权所有 © 中国科学院地理科学与资源研究所 | 备案序号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color:deepskyblue">京ICP备05002838号-59</a></h4>'), true)

        }


    }

    render() {
        if (this.props.shouldRedirect) {
            return <Redirect to={this.props.redirect}/>
        }
        return (
            <>
                <Row style={{height: '100vh'}}>
                    <Col span={24}>
                        <Viewer full={true}
                                imageryProvider={new UrlTemplateImageryProvider({
                                    url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"

                                })}
                                animation={false}
                                baseLayerPicker={false}
                                fullscreenButton={false}
                                vrButton={false}
                                geocoder={false}
                                homeButton={false}
                                infoBox={false}
                                sceneModePicker={false}
                                selectionIndicator={false}
                                timeline={false}
                                navigationHelpButton={false}
                                navigationInstructionsInitiallyVisible={false}
                                scene3DOnly={false}
                                shouldAnimate={false}


                                ref={e => {
                                    this.viewer = e && e.cesiumElement;
                                }}


                        />
                    </Col>
                </Row>

                <div className={"login-box"}>
                    <div className={"login-logo"}/>
                    <div id={"components-login"}>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{remember: true}}
                            onFinish={this.doLogin}
                        >
                            <Form.Item
                                name="principal"
                                type="text"
                                rules={[{required: true, message: '请输入用户名/邮箱'}]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="用户名/邮箱"/>
                            </Form.Item>
                            <Form.Item
                                name="credentials"
                                rules={[{required: true, message: '请输入密码'}]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    type="password"
                                    placeholder="密码"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>记住我</Checkbox>
                                </Form.Item>

                                <Button type={"link"} className="login-form-forgot" href="/user/resetPassword">
                                    忘记密码?
                                </Button>

                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    登录
                                </Button>
                            </Form.Item>

                            <Button type={"link"} className="goto-register" href="/register">
                                没有账号？去注册
                            </Button>
                        </Form>
                    </div>

                </div>
            </>

        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        shouldRedirect: (state => state.login.shouldRedirect)(state),
        redirect: (state => state.login.redirect)(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(loginActions, dispatch)
    }
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(Login));
