import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import configureStore from "./redux/configureStore";
import 'moment/locale/zh-cn';


import Login from "./components/pages/Login";

import Register from './components/pages/Register.jsx'


import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import UserProfile from "./components/pages/UserProfile";
import App from './components/App';
import ResetPassword from "./components/pages/ResetPassword";

moment.locale('zh-cn');

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <Provider store={configureStore()}>
            <Router>
                <Switch>
                    <Route exact path='/' component={App}/>
                    <Route exact path='/login' component={Login}/>
                    <Route exact path='/register' component={Register}/>
                    <Route exact path='/user/resetPassword' component={ResetPassword}/>
                    <Route exact path='/user/profile' component={UserProfile}/>
                </Switch>
            </Router>
        </Provider>

    </ConfigProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
